import React from "react";

export default function BrandInfoCard(item) {
  return (
    <div className="brand-info-card">
      <div className="content">
        <h1>About Brand</h1>
        <p>{item?.desc}</p>
      </div>
      <div className="image-box">
        <img src={item?.image} alt="" />
      </div>
    </div>
  );
}
