import React, { useEffect, useState } from "react";
import BrandInfoCard from "../../components/Cards/BrandInfoCard";
import { getBrandinfo } from "../../actions/BrandStore";
export default function AboutBrand({ id }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getBrandinfo(id, setData, setLoading);
  }, [id]);

  return (
    <div>
      {!loading && (
        <BrandInfoCard
          name={data[0]?.name}
          desc={data[0]?.about_brand}
          image={data[0]?.image}
        />
      )}
    </div>
  );
}
