import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  listingData: [],
  listingData2: {},
  loading: true,
  page: 1,
};

const listingSlice = createSlice({
  name: "listing",
  initialState: defaultState,
  reducers: {
    setListingData: (state, action) => {
      state.listingData = action.payload;
    },
    setListingData2: (state, action) => {
      state.listingData2 = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },

    resetListingReducer: (state) => (state = defaultState),
  },
});

export const {
  setListingData,
  setListingData2,
  setLoading,
  setPage,
  resetListingReducer,
} = listingSlice.actions;
export default listingSlice.reducer;
