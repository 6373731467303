import React from "react";
import Logo from "../../images/Topbar/Kavishala-24.svg";
import Logo2 from "../../images/Topbar/logo.png";
import search from "../../images/Topbar/search.svg";
import heart from "../../images/Topbar/heart.svg";
import cart from "../../images/Topbar/cart.svg";
import menu from "../../images/Topbar/menu.svg";
import user from "../../images/Topbar/user.svg";
import downArrow from "../../images/Topbar/downArrow.svg";
import PrimaryCTA from "../StyledComponent/PrimaryCTA";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { resetUserReducer } from "../../reducer/UserReducer";
import { resetCartReducer } from "../../reducer/CartReducer";
import MobileNavifation from "./MobileNavifation";
export default function AppBar({ openDrawer }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const { cartId, cartItems } = useSelector((state) => state.Cart);
  const { wishlistItems } = useSelector((state) => state.WishList);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(resetUserReducer());
    dispatch(resetCartReducer());
    localStorage.removeItem("store_token");
    localStorage.clear();
    history.push("/login");
  };

  return (
    <>
      <div className="appbar">
        <img
          className="logo"
          onClick={() => history.push("/")}
          src={Logo}
          alt=""
        />
        <div className="right">
          <div>
            <img
              className="Appbar-cart-icon"
              onClick={() => history.push("/search")}
              src={search}
              alt=""
            />
          </div>
          <div onClick={() => history.push("/wishlist")}>
            <img className="Appbar-cart-icon" src={heart} alt="" />
            {localStorage?.getItem("store_token") ? (
              <span
                className={wishlistItems?.length === 0 ? "" : "CartItems_Value"}
              >
                {wishlistItems?.length === 0 ? "" : wishlistItems?.length}
              </span>
            ) : (
              ""
            )}
          </div>
          <div onClick={() => history.push("/cart")}>
            <img className="Appbar-cart-icon" src={cart} alt="" />
            <span className={cartItems?.length === 0 ? "" : "CartItems_Value"}>
              {cartItems?.length === 0 ? "" : cartItems?.length}
            </span>
          </div>
          {isLogin ? (
            <div onClick={handleClick} className="user-menu">
              <img className="Appbar-cart-icon" src={user} alt="" />
              <h1 style={{ textTransform: "capitalize" }}>
                Hi {userInfo?.username}
              </h1>
              <img className="down-arrow" src={downArrow} alt="" />
            </div>
          ) : (
            <PrimaryCTA
              onClick={() => history.push("/login")}
              text="Login/Signup"
            />
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                history.push("/user-info");
                handleClose();
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/account");
                handleClose();
              }}
            >
              My account
            </MenuItem>
            {localStorage.getItem("isPartner") === "true" ? (
              <MenuItem
                onClick={() => {
                  history.push("/vendor-dashboard");
                  handleClose();
                }}
              >
                Vendor Dashboard
              </MenuItem>
            ) : (
              ""
            )}

            <MenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>

          <img
            className="Appbar-drawer-icon"
            onClick={openDrawer}
            src={menu}
            alt=""
          />
        </div>
      </div>
      <div className="mobile-appbar">
        <img
          className="logo"
          onClick={() => history.push("/")}
          src={Logo}
          alt=""
        />
        <div className="right">
          {/* <div>
            <img
              className="Appbar-cart-icon"
              onClick={() => history.push("/search")}
              src={search}
              alt=""
            />
          </div>
          <div onClick={() => history.push("/wishlist")}>
            <img className="Appbar-cart-icon" src={heart} alt="" />
            {localStorage.getItem("store_token") ? (
              <span
                className={wishlistItems.length === 0 ? "" : "CartItems_Value"}
              >
                {wishlistItems.length === 0 ? "" : wishlistItems.length}
              </span>
            ) : (
              ""
            )}
          </div>
          <div onClick={() => history.push("/cart")}>
            <img className="Appbar-cart-icon" src={cart} alt="" />
            <span className={cartItems.length === 0 ? "" : "CartItems_Value"}>
              {cartItems.length === 0 ? "" : cartItems.length}
            </span>
          </div>
          {isLogin ? (
            <div onClick={handleClick} className="user-menu">
              <img className="Appbar-cart-icon2" src={user} alt="" />
              <h1>Hi {userInfo["fullname"]}</h1>
              <img className="down-arrow" src={downArrow} alt="" />
            </div>
          ) : (
            <PrimaryCTA onClick={() => history.push("/login")} text="Login" />
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                history.push("/user-info");
                handleClose();
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/account");
                handleClose();
              }}
            >
              My account
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu> */}
          <img
            className="Appbar-drawer-icon"
            onClick={openDrawer}
            src={menu}
            alt=""
          />
        </div>
      </div>
      <MobileNavifation />
    </>
  );
}
