import React from "react";
import Starfilled from "./../../images/Cards/star-filled.svg";
import Star from "./../../images/Cards/star.svg";

export default function ReviewCard({ data }) {
  return (
    <div className="review-card">
      <div className="top">
        <div className="image-container">
          <img src={data?.image} alt="" />
        </div>
        <div className="user-info">
          <h1>{!data?.name ? data?.title : data?.name}</h1>
          <div className="rating">
            {Array(data?.rating)
              .fill()
              .map((e, _i) => (
                <img src={Starfilled} key={_i} alt="" />
              ))}
            {Array(5 - data?.rating)
              .fill()
              .map((e, _i) => (
                <img src={Star} key={_i} alt="" />
              ))}
          </div>
        </div>
      </div>
      <div className="content">
        <p>{data?.body}</p>
      </div>
    </div>
  );
}
