import React, { useEffect, useState } from "react";
import MyOrderCard from "../../components/Cards/MyOrderCard";
import { getMyOrderData } from "../../actions/MyOrder";
export default function MyOrdersContiner() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMyOrderData(setData, setLoading);
  }, []);

  return (
    <div className="myorder-box">
      <h1 className="myorder-txt">My Order</h1>
      <div>
        {data?.map((data, index) => (
          <div className="myorder-card-box" key={index}>
            <div className="top">
              <h3>
                ORDER ID : <span>{data?.order_id}</span>{" "}
              </h3>
              {/* <p> Order Success</p> */}
            </div>

            {data?.order_items?.map((orderData, key) => (
              <MyOrderCard data={orderData} key={key} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
