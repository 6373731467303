import React from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { useHistory } from "react-router-dom";

export default function LoginFooter() {
  const history = useHistory();
  return (
    <div className="login-footer">
      <div className="flex-center">
        <div className="divider"></div>
      </div>

      <h1>New to Kavishala Store?</h1>

      <PrimaryCTA
        onClick={() => history.push("/sign-up")}
        text="Create your Kavishala Account"
      />
    </div>
  );
}
