import React, { useEffect, useState } from "react";
import DropDownGroup from "./DropDownGroup";
import { getListingFilterAttributes } from "../../actions/Listing";
import { useSelector } from "react-redux";
import { setListingData, setPage } from "../../reducer/ListingReducer";
import { useDispatch } from "react-redux";
const filertData = [
  "Price : High to Low",
  "Price : Low to High",
  "Rating : Ascending",
  "Rating : Descending",
];

export default function RightPanelTopBar() {
  const dispatch = useDispatch();

  const { listingData, page } = useSelector((state) => state.Listing);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);

  const handleFilterClick = (index) => {
    setSelectedFilter(index);
    let temp = [...listingData];

    switch (index) {
      case 0:
        temp.sort((a, b) => b?.price - a?.price);
        dispatch(setListingData(temp));
        break;
      case 1:
        temp.sort((a, b) => a?.price - b?.price);
        dispatch(setListingData(temp));
        break;
      case 2:
        temp.sort((a, b) => a?.rating - b?.rating);
        dispatch(setListingData(temp));
        break;
      case 3:
        temp.sort((a, b) => b?.rating - a?.rating);
        dispatch(setListingData(temp));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getListingFilterAttributes(setData, setLoading);
  }, []);

  useEffect(() => {
    setSelectedFilter(undefined);
  }, [page]);

  return (
    <div className="listing-right-panel-topbar">
      {/* <DropDownGroup title="Size" /> */}
      <DropDownGroup
        title={
          selectedFilter === undefined
            ? "Select Filter"
            : filertData[selectedFilter]
        }
        handleFilterClick={handleFilterClick}
        data={filertData}
      />
    </div>
  );
}
