import React from "react";
const DashboardBtn = ({ selected, setSelected }) => {
  var navs;

  navs = [
    { key: "EARN", text: "Products" },
    { key: "REDEEM", text: "Orders" },
  ];

  return (
    <div className="dashboard">
      {navs.map(({ key, text }) => (
        <div
          key={key}
          id={selected === key ? "selected_btn_id" : null}
          className="selected-btn"
          onClick={() => setSelected(key)}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default DashboardBtn;
