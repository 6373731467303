import React, { useState } from "react";
import ItemCard from "../../components/Cards/ItemCard";
import SkeletonCard from "../../components/Cards/SkeletonCard";
import NoImage from "./../../images/Cards/no-image.svg";
import { Rating } from "@mui/material";

const Loader = () => {
  return Array(4)
    .fill()
    .map((e, i) => <SkeletonCard key={i} />);
};

export default function SearchItemContainer({ loading, result }) {
  return (
    <div className="search-item-container">
      <div className="header">
        <h1>Search Result</h1>
      </div>
      <div className="card-container">
        {loading ? (
          <Loader />
        ) : (
          result?.map((data) => (
            <ItemCard
              key={data?.id}
              id={data?.id}
              image={data?.image || NoImage}
              price={data?.price}
              discountPrice={data?.discount_price}
              name={data?.name}
              rating={data?.rating}
              slug={data?.slug}
            />
          ))
        )}
      </div>
    </div>
  );
}
