import React, { useState } from "react";
import LogoTopBar from "../../components/TopBar/LogoTopBar";
import SignUpContainer from "./Container";
import Toast from "../../components/Toast";
import SEO from "../../SEO";
export default function Sigup() {
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  return (
    <div className="signup-screen">
      <SEO
        title={`Sign Up | Kavishala Store `}
        og_url={"/sign-up"}
        og_type="e-commerce"
        og_title={`Sign Up | Kavishala Store  `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <LogoTopBar />
      <SignUpContainer setToast={setToast} />
      <Toast
        data={toast}
        handleClose={() =>
          setToast({
            open: false,
            isSuccess: false,
            msg: "",
          })
        }
      />
    </div>
  );
}
