import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./Signup.scss";
import Api from "../../core/Api";
import { useHistory } from "react-router-dom";
import AppBar from "../../components/TopBar/AppBar";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import SEO from "../../SEO";
const PublishForm = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxes2, setSelectedCheckboxes2] = useState([]);
  const [errors, setError] = useState(false);
  const [img, setImg] = useState();
  const [appDrawer, setAppDrawer] = useState(false);
  const [imageURLS, setImageURLs] = useState();

  const checkboxes = [
    { id: 1, text: "Urdu" },
    { id: 2, text: "Hindi" },
    { id: 3, text: "English" },
    { id: 4, text: "Other" },
  ];
  const checkboxes2 = [
    { id: 1, text: "Hardbound" },
    { id: 2, text: "E-Books" },
    { id: 3, text: "Paperback" },
    { id: 4, text: "Other" },
  ];
  const handleChangeCheckBox = (value) => {
    const selectedCheckboxess = selectedCheckboxes;

    const findIdx = selectedCheckboxess.indexOf(value?.text);

    if (findIdx > -1) {
      selectedCheckboxess.splice(findIdx, 1);
    } else {
      selectedCheckboxess.push(value?.text);
    }

    setSelectedCheckboxes(selectedCheckboxess);
  };
  const handleChangeCheckBox2 = (value) => {
    const selectedCheckboxess = selectedCheckboxes2;

    const findIdx = selectedCheckboxess.indexOf(value?.text);

    if (findIdx > -1) {
      selectedCheckboxess.splice(findIdx, 1);
    } else {
      selectedCheckboxess.push(value?.text);
    }

    setSelectedCheckboxes2(selectedCheckboxess);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  var publisher_language =
    selectedCheckboxes.toString() + "," + data?.publishing_language;

  var bookType =
    selectedCheckboxes2.toString() + "," + data?.book_type_available;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data2 = {
      publisher_name: data?.publisher_name,
      contact_person_name: data?.contact_person_name,
      phone_no: data?.phone_no,
      email_id: data?.email_id,
      office_location: data?.office_location,
      shipping_location: data?.shipping_location,
      established_year: data?.established_year,
      publishing_language: publisher_language,
      available_books: data?.available_books,
      book_type_available: bookType,
      note: data?.note,
    };
    Api.publishDetails(data2)
      .then((res) => {
        if (res?.status === 200) {
          history.push("/");
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const profile_pic = localStorage.getItem("Dp");

  return (
    <>
      <SEO
        title={`Publish Form | Kavishala Store`}
        og_url={"/publisher"}
        og_type="e-commerce"
        og_title={`Publish Form  | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
      <div className="signupBox">
        <div className="SignInContainer">
          <div className="HindiLogo">
            <div className="logo">
              <h1 className="first" onClick={() => history.push("/")}>
                KAVISHALA <span className="second"> STORE</span>
              </h1>
            </div>
          </div>
          {/* <div className="SignIn">
          <h1 className="SignInHead">Publish</h1>
        </div> */}
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              id="name"
              label="Publisher Name"
              type="text"
              variant="standard"
              className="password"
              name="publisher_name"
              onChange={handleChange}
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="Contact Person Name"
              label="Contact Person Name"
              type="text"
              variant="standard"
              className="password"
              name="contact_person_name"
              onChange={handleChange}
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="Contact No"
              label="Contact No"
              type="number"
              variant="standard"
              className="password"
              name="phone_no"
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="standard"
              className="inputText"
              name="email_id"
              onChange={handleChange}
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="Office Location"
              label="Office Location"
              type="text"
              variant="standard"
              className="password"
              onChange={handleChange}
              name="office_location"
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="Shipping location"
              label="Shipping location"
              type="text"
              variant="standard"
              className="password"
              onChange={handleChange}
              name="shipping_location"
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <TextField
              id="Established Year"
              label="Established Year"
              type="date"
              variant="standard"
              className="password"
              onChange={handleChange}
              name="established_year"
              format="MM-DD-YYYY"
              defaultValue="1940-05-24"
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />

            {/* <div> */}
            <h4 className="publishHead">Publishing Languages</h4>
            {checkboxes?.slice(0, 3)?.map((checkbox) => (
              <>
                <label key={checkbox.id} className="publish_check_box">
                  <input
                    type="checkbox"
                    // onChange={() => this.onChange(checkbox.id)}
                    // selected={selectedCheckboxes.includes(checkbox.id)}
                    name="publishing_language"
                    onChange={() => handleChangeCheckBox(checkbox)}
                    value={checkbox.text}
                  />
                  {checkbox.text}
                </label>
              </>
            ))}
            <div className="checkBox_container">
              <label key={checkboxes[3]?.id} className="publish_check_box">
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckBox(checkboxes[3])}
                  name="publishing_language"
                />
                {checkboxes[3]?.text}
              </label>
              <TextField
                id="Publishing Languages"
                type="text"
                variant="standard"
                className="checkbox_feild"
                onChange={handleChange}
                name="publishing_language"
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />
            </div>
            {/* </div> */}
            <TextField
              id="No. of available books"
              label="No. of available books"
              type="number"
              variant="standard"
              className="password"
              onChange={handleChange}
              name="available_books"
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />

            <h4 className="publishHead">Book types available</h4>
            {checkboxes2?.slice(0, 3)?.map((checkbox) => (
              <>
                <label key={checkbox.id} className="publish_check_box">
                  <input
                    type="checkbox"
                    name="book_type_available"
                    onChange={() => handleChangeCheckBox2(checkbox)}
                    value={checkbox.text}
                  />
                  {checkbox.text}
                </label>
              </>
            ))}
            <div className="checkBox_container">
              <label key={checkboxes[3]?.id} className="publish_check_box">
                <input
                  type="checkbox"
                  name="book_type_available"
                  onChange={() => handleChangeCheckBox2(checkboxes[3])}
                />
                {checkboxes[3]?.text}
              </label>
              <TextField
                id="Publishing Languages"
                type="text"
                variant="standard"
                className="checkbox_feild"
                onChange={handleChange}
                name="book_type_available"
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />
            </div>
            <TextField
              id="Course"
              label="Note"
              rows={4}
              multiline
              type="text"
              variant="standard"
              className="password"
              onChange={handleChange}
              name="note"
              required
              InputLabelProps={{
                style: { fontSize: "16px" },
              }}
              InputProps={{
                style: { fontSize: "16px" },
              }}
            />
            <div className="btnContainer">
              <Button
                variant="contained"
                className="LoginBtn"
                type="submit"
                style={{ fontSize: "16px" }}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PublishForm;
