import React, { useState } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import ChangePasswordContainer from "./ChangePasswordContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import Toast from "../../components/Toast";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function ChangePassword() {
  const [appDrawer, setAppDrawer] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  return (
    <>
      {" "}
      <SEO
        title={`Change Password | Kavishala Store`}
        og_url={"/change-password"}
        og_type="e-commerce"
        og_title={`Change Password | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="change-password">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="change-password-container">
          <ChangePasswordContainer setToast={setToast} />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
        <Toast
          data={toast}
          handleClose={() =>
            setToast({
              open: false,
              isSuccess: false,
              msg: "",
            })
          }
        />
      </div>
    </>
  );
}
