import React, { useState, useEffect } from "react";
import ItemCard from "../../components/Cards/ItemCard";
import SkeletonCard from "../../components/Cards/SkeletonCard";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { getBrandProduct } from "../../actions/BrandStore";
import { useHistory } from "react-router-dom";
import NoImage from "./../../images/Cards/no-image.svg";

export default function BrandProduct({ id }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // setLoading(false);
    getBrandProduct(id, setData, setLoading);
  }, [id]);

  return (
    <div className="brand-store-product">
      <h1>Products</h1>
      <div className="card-container">
        {loading
          ? Array(4)
              .fill()
              .map((e, i) => <SkeletonCard key={i} />)
          : data?.map((data) => (
              <ItemCard
                key={data?.id}
                id={data?.id}
                image={data?.image || NoImage}
                price={data?.price}
                discountPrice={data?.discount_price}
                name={data?.title}
                rating={data?.rating}
                slug={data?.slug}
              />
            ))}
      </div>
      <div className="cta-container">
        <PrimaryCTA
          onClick={() => history.push("/catalogue")}
          text="SEE PRODUCT LISTING"
        />
      </div>
    </div>
  );
}
