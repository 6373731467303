import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Account from "../screens/Account/Account";
import BrandStore from "../screens/BrandStore/BrandStore";
import Cart from "../screens/Cart/Cart";
import ChangePassword from "../screens/ChangePassword/ChangePassword";
import ForgetPassword from "../screens/ForgetPassword/ForgetPassword";
import Home from "../screens/Home/Home";
import Listing from "../screens/Listing/Listing";
import Login from "../screens/Login/Login";
import MyOrder from "../screens/MyOrder/MyOrder";
import PersonalDetails from "../screens/PersonalDetails/PersonalDetails";
import ResetPassword from "../screens/ResetPassword/ResetPassword";
import Signup from "../screens/SignUp/SignUp";
import WishList from "../screens/WishList/WishList";
import ShippingAddress from "../screens/ShippingAddress/ShippingAddress";
import Address from "../screens/Address/Address";
import AddAddress from "../screens/AddAddress/AddAddress";
import ProductDetails from "../screens/ProductDetails/ProductDetails";
import EditAddress from "../screens/EditAddress/EditAddress";
import Checkout from "../screens/Checkout/Checkout";
import PaymentSuccess from "../screens/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "../screens/PaymentFailed/PaymentFailed";
import Search from "../screens/Search/Search";
import Sitemap from "../components/Sitemap";
import PublishForm from "../screens/Publish/PublishForm";
import PublishOnbordingForm from "../screens/Publish/PublishOnbordingForm";
import Mydashboard from "../screens/Dashboard/Mydashboard";
export default function Router() {
  const { isLogin } = useSelector((state) => state.User);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/catalogue" component={Listing} />
        <Route exact path="/brand-store/:id" component={BrandStore} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/catalogue/:slug" component={ProductDetails} />
        <Route exact path="/search" component={Search} />
        {isLogin ? (
          <>
            <Route exact path="/wishlist" component={WishList} />
            <Route exact path="/vendor-dashboard" component={Mydashboard} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/myorder" component={MyOrder} />
            <Route exact path="/user-info" component={PersonalDetails} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/shipping-address" component={ShippingAddress} />
            <Route exact path="/address" component={Address} />
            <Route exact path="/add-address" component={AddAddress} />
            <Route exact path="/edit-address/:id" component={EditAddress} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/publisher" component={PublishForm} />
            <Route
              exact
              path="/publisher-onbording"
              component={PublishOnbordingForm}
            />
            <Route exact path="/sitemap.xml" component={Sitemap} />
            <Route
              exact
              path="/payment-success/:id"
              component={PaymentSuccess}
            />
            <Route exact path="/payment-failed/:id" component={PaymentFailed} />
          </>
        ) : (
          <Redirect to="/" />
        )}
      </Switch>
    </BrowserRouter>
  );
}
