import React from "react";

export default function TextInput({ title, ...rest }) {
  return (
    <div className="styled-text-input">
      <p>{title} </p>
      <input {...rest} />
    </div>
  );
}
