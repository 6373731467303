import React, { useState, useEffect } from "react";
import CollaspeIcon from "./../../images/Home/collaspe.svg";
import ExpandIcon from "./../../images/Home/expand.svg";
import { getHomeFaq } from "../../actions/Home";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const FaqCard = ({ data }) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div className="faq-card">
      <div className="top">
        <p>{data?.title}</p>
        <img
          onClick={() => setIsExpand(!isExpand)}
          src={isExpand ? ExpandIcon : CollaspeIcon}
          alt=""
        />
      </div>
      <div className="bottom">{isExpand && ReactHtmlParser(data?.content)}</div>
    </div>
  );
};

export default function FAQS() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    getHomeFaq(setData, setLoading);
  }, []);

  return (
    <div className="home-faq">
      {data?.map((data) => (
        <FaqCard data={data} key={data?.id} />
      ))}
    </div>
  );
}
