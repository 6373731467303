import React from "react";
import PrimaryCTA from "../StyledComponent/PrimaryCTA";
import { useHistory } from "react-router-dom";

export default function CarouselCard({ data }) {
  const history = useHistory();

  return (
    <div className="carousel-card">
      <div className="left">
        <h1>{data?.name}</h1>
        <p>{data?.desciption}</p>
        <div className="images">
          {data?.product_image?.slice(0, 2).map((e, index) => (
            <>
              <img src={e?.image} key={index} alt="" />
            </>
          ))}
        </div>
        <PrimaryCTA
          text="SHOP NOW"
          onClick={() => history.push(`/catalogue`)}
        />
      </div>

      <div className="right">
        <img src={data?.image} alt="" />
      </div>
    </div>
  );
}
