import React from "react";
import Logo from "./../../images/Footer/K.svg";
import FaceBook from "./../../images/Footer/Facebook.svg";
import Twitter from "./../../images/Footer/Twitter.svg";
import Instagram from "./../../images/Footer/Instagram.svg";
import Youtube from "./../../images/Footer/Youtube.svg";

export default function DesktopFooter() {
  return (
    <div className="desktop-footer">
      <div className="top">
        <div className="div1">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="content">
            <h1>Kavishala Store</h1>
            <p>
              <span> &#169;</span> Copyright 2021.
            </p>
            <div className="social-links">
              <img
                src={FaceBook}
                alt=""
                onClick={() =>
                  window.open("https://www.facebook.com/KavishalaStore")
                }
              />
              <img
                src={Twitter}
                alt=""
                onClick={() =>
                  window.open("https://twitter.com/KavishalaStore")
                }
              />
              <img
                src={Instagram}
                alt=""
                onClick={() =>
                  window.open("https://www.instagram.com/kavishalastore/")
                }
              />
              <img
                src={Youtube}
                alt=""
                onClick={() =>
                  window.open("https://www.instagram.com/kavishalastore/")
                }
              />
            </div>
          </div>
        </div>
        <div className="footer-section">
          <h1>ABOUT</h1>
          <h2>About Us</h2>

          <h2
            onClick={() => window.open("https://kavishala.in/store-Contact-Us")}
          >
            Contact Us
          </h2>
          <h2
            onClick={() =>
              window.open("https://kavishala.in/store-Privacy-Policy")
            }
          >
            Sell With Us
          </h2>
        </div>
        <div className="footer-section">
          <h1>HELP</h1>

          <h2> Cancellation & Returns</h2>
          <h2
            onClick={() =>
              window.open("https://kavishala.in/store-Track-Your-Order")
            }
          >
            Track & Order
          </h2>
          <h2>FAQ</h2>
        </div>
        <div className="footer-section">
          <h1>POLICY</h1>
          <h2
            onClick={() =>
              window.open("https://kavishala.in/store-Privacy-Policy")
            }
          >
            Privacy Policy{" "}
          </h2>
          <h2
            onClick={() =>
              window.open("https://kavishala.in/store-Terms-and-Conditions")
            }
          >
            Terms & Conditions
          </h2>
          <h2 onClick={() => window.open("https://kavishala.in/store-Returns")}>
            Return Policy
          </h2>
        </div>
      </div>
      <div className="buttom"></div>
    </div>
  );
}
