import React, { useState, useEffect } from "react";
import TopBar from "./TopBar";
import "../Dashboard/dashboard.css";
import RightPanel from "./RightPanel";
import Order from "./Order";
const LeftBar = () => {
  const [selected, setSelected] = useState("");
  const getComponent = () => {
    switch (selected) {
      case "REDEEM":
        return <Order />;
      case "EARN":
        return <RightPanel />;

      default:
        return <h1 style={{ margin: "0" }}>No records found</h1>;
    }
  };
  useEffect(() => {
    setSelected("EARN");
  }, []);
  const data = {
    text: "History",
    head: "Available",
  };
  return (
    <div className="Dashbord_container">
      <TopBar selected={selected} setSelected={setSelected} data={data} />
      <div style={{ width: "100%" }}>{getComponent()}</div>
    </div>
  );
};

export default LeftBar;
