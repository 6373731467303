import React, { useState } from "react";
import TextInput from "../../components/StyledComponent/TextInput";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { addNewAddress, changeUserPassword } from "../../actions/User";
import { useHistory } from "react-router-dom";
import DropDown from "../../components/StyledComponent/DropDown";
import BackArrow from "../../images/Modal/BackArrow.png";
import { baseURL } from "../../core/Api";

const titleArray = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
const addressArray = ["Home", "Office", "Other"];

export default function AddressContainer({ setToast }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [tstate, setTstate] = useState("");
  const [pin, setPin] = useState("");
  const [title, setTitle] = useState("Mr");
  const [addressName, setAddressName] = useState("Home");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phone.length !== 10) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Phone Number must be of 10 digits",
      });
      return;
    }
    if (pin?.toString()?.length !== 6) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Pin Code must be of 6 digits",
      });
      return;
    }

    const data = {
      address_name: addressName?.toLowerCase(),
      name: name,
      address_line_1: line1,
      address_line_2: line2,
      address_line_3: line3,
      city: city,
      state: tstate,
      pincode: pin,
      phone: `+91${phone}`,
      notes: "",
      is_default_for_shipping: true,
      is_default_for_billing: true,
      country: `${baseURL}api/countries/IN/`,
    };

    setLoading(true);
    addNewAddress(data, setLoading, setToast, history);
  };

  return (
    <div className="add-address-box">
      <div className="back-btn">
        <img
          className="backArrow"
          src={BackArrow}
          alt=""
          onClick={() => history.push("/shipping-address")}
        />
        <div className="header">
          <h1>Add Address</h1>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="card-container">
        <DropDown
          title="Save Address As"
          list={addressArray}
          value={addressName}
          onChange={(e) => setAddressName(e.target.value)}
          style={{ fontSize: "18px" }}
          className="dropdown"
        />
        {/* <DropDown
          title="Title"
          list={titleArray}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        /> */}
        <TextInput
          title="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextInput
          title="Address"
          value={line1}
          onChange={(e) => setLine1(e.target.value)}
          required
          placeholder="line1"
        />
        <TextInput
          value={line2}
          onChange={(e) => setLine2(e.target.value)}
          required
          placeholder="line2"
        />
        <TextInput
          value={line3}
          onChange={(e) => setLine3(e.target.value)}
          required
          placeholder="line3"
        />
        <TextInput
          type="number"
          title="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          placeholder=""
        />
        <div className="combine-container">
          <TextInput
            title="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            placeholder=""
          />
          <TextInput
            title="State"
            value={tstate}
            onChange={(e) => setTstate(e.target.value)}
            required
            placeholder=""
          />
        </div>
        <div className="combine-container">
          <TextInput
            title="Country"
            value={"India"}
            readonly
            // onChange={(e) => setName(e.target.value)}
            required
            placeholder=""
          />
          <TextInput
            title="PinCode"
            value={pin}
            type="number"
            onChange={(e) => setPin(e.target.value)}
            required
            placeholder=""
          />
        </div>
        <PrimaryCTA
          loading={loading}
          disabled={loading}
          type="submit"
          text="Save"
        />
      </form>
    </div>
  );
}
