import React from "react";
import { useHistory } from "react-router";
import { deleteUserAddress } from "../../actions/User";

export default function AddressCard({ data, refetch, setToast }) {
  const history = useHistory();
  const handleEdit = () => {
    history.push(`/edit-address/${data?.id}`);
  };
  const handleDelete = () => {
    deleteUserAddress(data?.id, refetch, setToast);
  };
  return (
    <div className="address-card">
      <div className="header">
        <h1>{data?.address_name} Address</h1>
        <div className="icons">
          <p onClick={handleEdit}>Edit</p>
          <p onClick={handleDelete}>Delete</p>
        </div>
      </div>

      <div className="container">
        <p>
          Name :<span>{data?.name},</span>
        </p>
        <p>
          Phone : <span>{data?.phone} </span>
        </p>
        <p>
          Line 1 : <span> {data?.address_line_1} </span>{" "}
        </p>
        <p>
          Line 2 : <span> {data?.address_line_2} </span>{" "}
        </p>
        <p>
          Line 3 : <span> {data?.address_line_3} </span>{" "}
        </p>
        <p>
          City : <span> {data?.city} </span>{" "}
        </p>
        <p>
          State : <span> {data?.state} </span>{" "}
        </p>
        <p>
          Country : <span>{data?.country} </span>{" "}
        </p>
        <p>
          Pin : <span> {data?.pincode} </span>{" "}
        </p>
      </div>
    </div>
  );
}
