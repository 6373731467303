import React, { useState } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import ShippingCard from "./ShippingCard";
import BackArrow from "../../images/Modal/BackArrow.png";

import { changeUserPassword } from "../../actions/User";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function ShippingAddressContainer({ setToast }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { userAddress, selectedIndex } = useSelector((state) => state.User);
  const { proccedToCheckOut } = useSelector((state) => state?.Cart);

  return (
    <div className="shipping-address-box">
      <div className="header">
        <div className="back-btn">
          <img
            className="backArrow"
            src={BackArrow}
            alt=""
            onClick={() => history.push("/cart")}
          />
          <h1>Shipping Address</h1>
        </div>
        <PrimaryCTA
          onClick={() => history.push("/add-address")}
          type="submit"
          text={"Add New Address"}
        />
      </div>

      <div className="card-container">
        {userAddress?.map((data, index) => (
          <ShippingCard
            setToast={setToast}
            index={index}
            data={data}
            key={data?.id}
          />
        ))}
      </div>
    </div>
  );
}
