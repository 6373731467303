import { Radio } from "@mui/material";
import React from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { useSelector } from "react-redux";
import { setSelectedIndex } from "../../reducer/UserReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export default function ShippingCard({ data, index }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedIndex } = useSelector((state) => state.User);

  return (
    <div className="shipping-card">
      <h1>{data?.address_name} Address</h1>
      <div className="container">
        <div className="left">
          <Radio
            checked={selectedIndex === index}
            onClick={() => dispatch(setSelectedIndex(index))}
          />
        </div>

        <div className="right">
          <div className="box">{data?.search_text}</div>
          <PrimaryCTA
            onClick={() => history.push("/checkout")}
            disabled={selectedIndex !== index}
            text="Ship to this address"
          />
        </div>
      </div>
    </div>
  );
}
