import React from "react";
import OrderIcon from "./../../images/Account/order.svg";
import PasswordIcon from "./../../images/Account/password.svg";
import infoIcon from "./../../images/Account/info.svg";
import addressIcon from "./../../images/Account/address.svg";
import AccountCard from "./AccountCard";

export default function AccountContainer() {
  const path = window?.location?.pathname;

  return (
    <div className="account-box">
      <h1 className="account-txt">
        {" "}
        {path === "/account" ? "Account" : "Payment Success"}
      </h1>

      <div className="card-container">
        <AccountCard
          title={"Your Orders"}
          img={OrderIcon}
          // desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "}
          path="/myorder"
        />
        <AccountCard
          title={"Personal Information"}
          img={infoIcon}
          // desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "}
          path="/user-info"
        />
        <AccountCard
          title={"Change Password"}
          img={PasswordIcon}
          // desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "}
          path="/change-password"
        />

        <AccountCard
          title={"Saved Addresses"}
          img={addressIcon}
          // desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "}
          path="/address"
        />
      </div>
    </div>
  );
}
