import React from "react";
import Logo from "../../images/Topbar/kavishala-36.svg";
import { useHistory } from "react-router-dom";
export default function LogoTopBar() {
  const history = useHistory();
  return (
    <div className="logo-top-bar">
      <img src={Logo} alt="" onClick={() => history.push("/")} />
    </div>
  );
}
