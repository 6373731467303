import React, { useEffect, useState } from "react";
import AppBar from "../../components/TopBar/AppBar";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import { getListingProducts } from "../../actions/Listing";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../reducer/ListingReducer";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import { useLocation } from "react-router";
import ItemModal from "../../components/Modals/ItemModal";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function Listing() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state?.App);
  const { page, listingData } = useSelector((state) => state.Listing);
  const [appDrawer, setAppDrawer] = useState(false);
  const path = window.location.pathname;
  localStorage.setItem("path", path);
  // useEffect(() => {
  //   let tempData = {
  //     partner: [],
  //     product_class: state?.id ? [state.id] : [],
  //     product_category: [],
  //   };
  //   dispatch(resetToast());
  //   dispatch(setLoading(true));
  //   dispatch(getListingProducts(page, state?.id ? tempData : {}));
  // }, [dispatch, page]);

  return (
    <div className="listing">
      <SEO
        title={`Catalogue | Kavishala Store `}
        og_url={"/catalogue"}
        og_type="e-commerce"
        og_title={`Catalogue | Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="listing-container">
        <div className="listing-container-box">
          <LeftPanel />
          <RightPanel />
        </div>
        <DesktopFooter />
        <MobileFooter />
      </div>
      <ItemModal />
      <Toast data={toast} handleClose={() => dispatch(resetToast())} />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
