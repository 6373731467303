import React, { useEffect, useState } from "react";
import PriceRange from "./PriceRange";
import BrandContainer from "./BrandContainer";
import CatogeryContainer from "./CatogeryContainer";
import {
  getListingFilter,
  getProductFilterListing,
} from "../../actions/Listing";
import Drawer from "@mui/material/Drawer";
import { getHomeCatogery, getHomePartners } from "../../actions/Home";
import menu from "../../images/Topbar/menu.svg";
import Filter from "../../images/Cards/filter.png";
export default function LeftPanel() {
  const [data, setData] = useState([]);
  const [parters, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [appDrawer, setAppDrawer] = useState(false);

  useEffect(() => {
    getProductFilterListing(setData, setLoading);
    getListingFilter(setData, setLoading);
    getHomePartners(setPartners, setLoading);
  }, []);
  const openDrawer = () => {
    setAppDrawer(true);
  };
  const handleClose = () => setAppDrawer(false);
  
  return (
    <>
      <div className="listing-left-panel">
        <div className="filter-by">
          <h1>Filter By</h1>
        </div>
        <CatogeryContainer data={data} />
        <BrandContainer data={parters} />
        <PriceRange />
      </div>
      <div className="humburg">
        <img
          className="humburg-drawer-icon"
          onClick={openDrawer}
          src={Filter}
          alt=""
        />
      </div>
      <Drawer
        anchor={"left"}
        open={appDrawer}
        onClose={handleClose}
        handleClose={() => setAppDrawer(false)}
      >
        <div className="listing-left-panel2">
          <div className="filter-by">
            <h1>Filter By</h1>
          </div>
          <CatogeryContainer data={data} />
          <BrandContainer data={parters} />
          <PriceRange />
        </div>
      </Drawer>
    </>
  );
}
