import Api from "../core/Api";
import {
  setLoading,
  setListingData,
  setPage,
  setListingData2,
} from "../reducer/ListingReducer";

export const getListingProducts = (page, data) => {
  return (dispatch) => {
    Api.getListingProduct(page, "category")
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setListingData2(res?.data?.count));
          dispatch(setListingData(res?.data?.results));
          dispatch(setLoading(false));
        }
      })
      .catch((e) => {
        dispatch(setPage(page - 1));
      });
  };
};
export const getProductFilterListing = (tempData, setData, setLoading) => {
  const id = tempData?.id;

  return (dispatch) => {
    Api.getProductFilterList(id)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setListingData(res?.data?.results));
          // setData(res?.data?.results);
          setLoading(false);
        }
      })
      .catch((e) => {});
  };
};
export const getProductRangeListing = (value2, setData, setLoading) => {
  return (dispatch) => {
    Api.getProductRangeList(value2)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setListingData(res?.data?.results));
          // setData(res?.data?.results);
          setLoading(false);
        }
      })
      .catch((e) => {});
  };
};

export const getListingFilter = (setData, setLoading) => {
  Api.getListingFilter()
    .then((res) => {
  
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};

export const getListingFilterAttributes = (setData, setLoading) => {
  Api.getListingFilterAttributes()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data?.results);
        setLoading(false);
      }
    })
    .catch((e) => {});
};
