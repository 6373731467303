import Router from "./router/Router";
import { store, persistor } from "./core/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
function App() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight / 100}px`
  );
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  );
}

export default App;
