import {
  getDefaultMiddleware,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import UserSlice from "./../reducer/UserReducer";
import ListingSlice from "./../reducer/ListingReducer";
import CartReducer from "./../reducer/CartReducer";
import WishListReducer from "./../reducer/WishlistReducer";
import AppReducer from "../reducer/AppReducer";

const rootReducer = combineReducers({
  User: UserSlice,
  Listing: ListingSlice,
  Cart: CartReducer,
  WishList: WishListReducer,
  App: AppReducer,
});

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
