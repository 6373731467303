import React, { useState, useEffect } from "react";
import Api from "../core/Api";
const Sitemap = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const loadData = () => {
      Api.getSitemap().then((res) => {
        setData(res?.data);
      });
    };
    loadData();
  }, []);
  return <pre>{data}</pre>;
};

export default Sitemap;
