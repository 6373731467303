import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  cartItems: [],
  cartId: null,
  proccedToCheckOut: null,
  couponDiscount: 0,
  coupanId: 0,
  coupon: "",
  isCouponApplied: false,
};
const cartSlice = createSlice({
  name: "cart",
  initialState: defaultState,
  reducers: {
    setCartItem: (state, action) => {
      state.cartItems = action.payload;
    },
    setCartId: (state, action) => {
      state.cartId = action.payload;
    },
    setProccedToCheckOut: (state, action) => {
      state.proccedToCheckOut = action.payload;
    },
    setCouponDiscount: (state, action) => {
      state.couponDiscount = action.payload;
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    setCoupanId: (state, action) => {
      state.coupanId = action.payload;
    },
    setisCouponApplied: (state, action) => {
      state.isCouponApplied = action.payload;
    },

    resetCartReducer: (state, action) => (state = defaultState),
  },
});

export const {
  setCartItem,
  setCartId,
  resetCartReducer,
  setProccedToCheckOut,
  setCouponDiscount,
  setCoupanId,
  setisCouponApplied,
  setCoupon,
} = cartSlice.actions;
export default cartSlice.reducer;
