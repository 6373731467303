import Api from "../core/Api";
import {
  setCartId,
  setCartItem,
  setProccedToCheckOut,
} from "../reducer/CartReducer";
import { setToast } from "../reducer/AppReducer";

export const addItemToCart = (data, setLoading, handleClose) => {
  return (dispatch) => {
    Api.addtoCart(data)
      .then((res) => {
        // localStorage.setItem("cartId", res?.data?.user_cart[0]?.basket);
        if (res?.status) {
          dispatch(setCartItem(res?.data?.user_cart));
          dispatch(
            setToast({ open: true, isSuccess: true, msg: res?.data?.msg })
          );
          setLoading(false);
          // handleClose();
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};

// export const createBasket = (cartId) => {
//   return (dispatch) => {
//     Api.createCart(cartId)
//       .then((res) => {
//         if (res?.data?.status) {
//           dispatch(setCartId(res?.data?.user_cart_id));
//         }
//       })
//       .catch((e) => {
//         console.log("Error", e);
//       });
//   };
// };

export const getCartItems = (isLogin, setLoading) => {
  return (dispatch) => {
    Api.getAllCartItems(isLogin)
      .then((res) => {
        if (res?.status) {
          dispatch(setCartItem(res?.data?.user_cart));
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};
export const updateCartItemQty = (data, setLoading) => {
  return (dispatch) => {
    Api.addtoCart(data)
      .then((res) => {
        if (res?.status) {
          dispatch(setCartItem(res?.data?.user_cart));
          dispatch(
            setToast({ open: true, isSuccess: true, msg: res?.data?.msg })
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};

export const removeItemFromCart = (data, setLoading) => {
  return (dispatch) => {
    Api.removeItem(data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setCartItem(res?.data?.user_cart));
          dispatch(
            setToast({ open: true, isSuccess: true, msg: res?.data?.msg })
          );
          // window.location.reload();
          setLoading(false);
        } else {
          dispatch(
            setToast({ open: true, isSuccess: false, msg: res?.data?.msg })
          );
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};

export const proccedToCheckout = (data, setLoading, history) => {
  return (dispatch) => {
    Api.processToCheckout(data)
      .then((res) => {
        if (res?.status) {
          dispatch(setProccedToCheckOut(res?.data));
          history.push("/shipping-address");
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};
export const buyNowProduct = (temp, setLoading2, history) => {
  return (dispatch) => {
    Api.buyNow(temp)
      .then((res) => {
        if (res?.status) {
          dispatch(setProccedToCheckOut(res?.data));
          history.push("/shipping-address");
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};
export const getDeliveyCharges = async (setshippingChrg) => {
  Api.getDeliveryCharge()
    .then((res) => {
      if (res?.status === 200) {
        setshippingChrg(res?.data);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
