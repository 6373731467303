import React, { useState, useEffect } from "react";
import ItemCard from "../Cards/ItemCard";
import SkeletonCard from "../Cards/SkeletonCard";
import Pagination from "@mui/material/Pagination";
import { useSelector } from "react-redux";
import { setPage, setListingData2 } from "../../reducer/ListingReducer";
import { useDispatch } from "react-redux";
import NoImage from "./../../images/Cards/no-image.svg";
import Api from "../../core/Api";
const Loader = () => {
  return Array(4)
    .fill()
    .map((e, i) => <SkeletonCard key={i} />);
};

export default function ListingCardContainers() {
  const dispatch = useDispatch();

  const { page, loading, listingData, listingData2 } = useSelector(
    (state) => state.Listing
  );
  const [totalCount, setTotalCount] = useState(1);
  const [data, setData] = useState([]);
  const handlePageChange = () => {
    setTotalCount(page);
  };
  var count = Math.ceil(listingData2 / 16);

  useEffect(() => {
    const loadData = () => {
      Api.productList().then((res) => {
        setData(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <>
      <div className="listing-card-container">
        {loading ? (
          <Loader />
        ) : (
          data?.map((data) => (
            <ItemCard
              key={data?.id}
              id={data?.id}
              image={data?.image_price_partner?.image_url || NoImage}
              price={data?.image_price_partner?.price}
              discountPrice={data?.image_price_partner?.discount_price}
              name={data?.title}
              rating={data?.rating}
              slug={data?.slug}
            />
          ))
        )}
      </div>
      <div className="listing-pegintion-container">
        {/* {!loading && ( */}
        {/* <Pagination
          onChange={(e, v) => dispatch(setPage(v))}
          count={count}
          size="small"
          page={page}
          variant="outlined"
          shape="rounded"
        /> */}
      </div>
    </>
  );
}
