import React, { useEffect, useState } from "react";

const CheckoutCartItems = ({ data }) => {
  return (
    <div className="checkout-cart-items">
      <div className="top">
        <p>
          {" "}
          {data?.quantity} X {data?.title}
        </p>
        <h1>₹ {data?.price_excl_tax}</h1>
      </div>
    </div>
  );
};

export default function CheckoutItems({
  data,
  total,
  couponDiscount,
  shippingChrg,
}) {
  return (
    <div className="checkout-items-container">
      <div className="header">
        <h1>Cart Items</h1>
      </div>
      <div className="container">
        {data?.check_out_cart_data?.map((data) => (
          <CheckoutCartItems data={data} />
        ))}

        <div className="footer">
          <div className="subtotal">
            <h1>Subtotal </h1>
            <h2>₹ {total}</h2>
          </div>
          {couponDiscount > 0 && (
            <div className="discount">
              <h1>Discount </h1>
              <h2>₹ {couponDiscount}</h2>
            </div>
          )}
        </div>
        <div className="total">
          <h1>Total </h1>
          <h2>₹ {(total - couponDiscount)?.toFixed(2)}</h2>
        </div>
        <div className="total">
          <h1>Shipping Charge </h1>
          <h2>₹ {shippingChrg?.amount}</h2>
        </div>
        <div className="total">
          <h1>Amount to Pay </h1>
          <h2>
            ₹ {(total - couponDiscount + shippingChrg?.amount)?.toFixed(2)}
          </h2>
        </div>
      </div>
    </div>
  );
}
