import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./Signup.scss";
import Api from "../../core/Api";
import { useHistory } from "react-router-dom";
import AppBar from "../../components/TopBar/AppBar";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { ToastContainer, toast } from "react-toastify";

const PublishOnbordingForm = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxes2, setSelectedCheckboxes2] = useState([]);
  const [errors, setError] = useState(false);
  const [img, setImg] = useState();
  const [appDrawer, setAppDrawer] = useState(false);
  const [imageURLS, setImageURLs] = useState();
  const [showFront, setShowFront] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const toggleComponent = () => {
    setShowFront((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("legal_name", data.legal_name);
    formData.append("contact_person_name", data.contact_person_name);
    formData.append("contact_person_no", data.contact_person_no);
    formData.append("csv_file", imageURLS);
    formData.append("gstin", data?.gstin);

    Api.publisOnbordinghDetails(formData)
      .then((res) => {
        if (res?.status === 200) {
          toast(res?.data.msg, {
            closeOnClick: false,
            closeButton: false,
            // type: toast.TYPE.SUCCESS,
            autoClose: 1500,
            position: toast.POSITION.BOTTOM_CENTER,
          });

          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageURLs(file);
  };
  const handleDownload = () => {
    window.open(
      "https://kavishala.blob.core.windows.net/kavishalalabs/product_list.csv"
    );
  };

  return (
    <>
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
      <div className="signupBox">
        {showFront ? (
          <div className="SignInContainer">
            <div className="HindiLogo">
              <div className="logo">
                <h1 className="first" onClick={() => history.push("/")}>
                  KAVISHALA <span className="second"> STORE</span>
                </h1>
              </div>
            </div>

            <form className="form" onSubmit={handleSubmit}>
              <TextField
                id="name"
                label="Publisher Name"
                type="text"
                variant="standard"
                className="password"
                name="name"
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" }, // Adjust the font size of the input box itself
                }}
              />
              <TextField
                id="name"
                label="Publication House Name"
                type="text"
                variant="standard"
                className="password"
                name="legal_name"
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />
              <TextField
                id="Contact Person Name"
                label="Contact Person Name"
                type="text"
                variant="standard"
                className="password"
                name="contact_person_name"
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />
              <TextField
                id="Contact No"
                label="Contact No"
                type="number"
                variant="standard"
                className="password"
                name="contact_person_no"
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                required
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />
              <TextField
                id="Gst No"
                label="Gst No"
                type="text"
                variant="standard"
                className="password"
                name="gstin"
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { fontSize: "16px" },
                }}
                InputProps={{
                  style: { fontSize: "16px" },
                }}
              />

              <div className="password">
                <input
                  accept=".csv,.pdf"
                  id="contained-button-file"
                  type="file"
                  text={"Select file"}
                  // style={{ display: "none" }}
                  name="csv_file"
                  onChange={handleFileChange}
                  required
                />
              </div>

              <div className="btnContainer">
                <Button
                  variant="contained"
                  className="LoginBtn"
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Submit
                  <ToastContainer />
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="SignInContainer">
            <div className="HindiLogo">
              <div className="logo">
                <h1 className="first" onClick={() => history.push("/")}>
                  KAVISHALA <span className="second"> STORE</span>
                </h1>
              </div>
            </div>
            <p style={{ fontSize: "16px", margin: "20px" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
            </p>
            <ul>
              <li style={{ fontSize: "16px", margin: "20px" }}>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text
              </li>
              <li style={{ fontSize: "16px", margin: "20px" }}>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text
              </li>
              <li style={{ fontSize: "16px", margin: "20px" }}>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text
              </li>
              <li style={{ fontSize: "16px", margin: "20px" }}>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text
              </li>
            </ul>
            <div style={{ textAlign: "center", margin: "20px" }}>
              <Button
                variant="contained"
                className="LoginBtn"
                type="submit"
                onClick={handleDownload}
                style={{ fontSize: "16px", background: "#00102c" }}
              >
                Download csv
              </Button>
            </div>
            <div
              className="btnContainer"
              style={{ textAlign: "right", paddingBottom: "30px" }}
            >
              <Button
                variant="contained"
                className="LoginBtn"
                type="submit"
                onClick={toggleComponent}
                style={{ fontSize: "16px" }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PublishOnbordingForm;
