import React, { useEffect, useState } from "react";
import AppBar from "../../components/TopBar/AppBar";
import FeatureProduct from "./FeatureProduct";
import Layout1 from "./../../components/Layout/Layout1";
import HomeProduct from "./HomeProduct";
import HomeOfferCard from "./HomeOfferCard";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import MobileFooter from "../../components/Footer/MobileFooter";
import HomeCarousel from "./HomeCarousel";
import { getHomeCover } from "../../actions/Home";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { useSelector, useDispatch } from "react-redux";
// import { createBasket } from "../../actions/Cart";
import ItemModal from "../../components/Modals/ItemModal";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import FAQS from "./FAQS";
import SEO from "../../SEO";

export default function Home() {
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state?.App);
  const [cover, setCover] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appDrawer, setAppDrawer] = useState(false);
  const { cartId } = useSelector((state) => state.Cart);
  const path = window.location.pathname;
  localStorage.setItem("path", path);
  useEffect(() => {
    setLoading(true);
    getHomeCover(setCover, setLoading);

    // if (cartId === null || cartId === undefined) {
    //   dispatch(createBasket());
    // }
  }, []);

  return (
    <div className="home">
      <SEO
        title={`Kavishala Store `}
        og_url={"/"}
        og_type="e-commerce"
        og_title={`Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="home-container">
        {!loading && <Layout1 data={cover} />}

        <FeatureProduct />
        <HomeCarousel />
        <HomeProduct />
        <HomeOfferCard />
        <FAQS />
        <DesktopFooter />
        <MobileFooter />
      </div>

      <Toast data={toast} handleClose={() => dispatch(resetToast())} />
      <ItemModal />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
