import React from "react";
import ListingCardContainer from "./ListingCardContainer";
import RightPanelTopBar from "./RightPanelTopBar";

export default function RightPanel() {
  return (
    <div className="listing-right-panel">
      <RightPanelTopBar />
      <ListingCardContainer />
    </div>
  );
}
