import React from "react";
import { Helmet } from "react-helmet";
var logo = "/images/favicon.ico";
const SEO = (props) => {
  console.log("props", props);
  return (
    <div>
      <Helmet>
        <link rel="icon" href={logo} />
        <link rel="apple-touch-icon" href={logo} />
        <title>{props.title || "Kavishala Academy"}</title>

        <meta property="og:type" content={props.og_type || "article"} />

        <meta property="og:locale" content="en" />
        <meta name="description" content={props.og_description} />
        <meta name="author" content={props.author || "Kavishala"} />
        <meta
          name="news_keywords"
          content={`Kavishala Store , Books ,T-Shirts, ${
            props.og_news_keywords === undefined ? "" : props.og_news_keywords
          }`}
        />
        <meta
          name="keywords"
          content={`Kavishala Store , Books ,T-Shirts, ${
            props.og_news_keywords === undefined ? "" : props.og_news_keywords
          }`}
        />

        {/* FACEBOOK TAGS */}
        <meta property="fb:app_id" content="2593857177316284" />
        <meta
          property="fb:pages"
          content="1366164690108763,127764270624437,464599680252351"
        />
        {/* (Facebook Page ID) */}
        <meta
          property="og:title"
          content={
            props.og_title
              ? `${props.og_title.slice(0, 110)}...`
              : "Kavishala | The school of poets"
          }
        />
        <meta property="og:description" content={props.og_description} />
        <meta property="og:url" content={props.og_url} />
        <meta property="og:site_name" content="store.kavishala.com" />
        <meta
          property="og:image"
          content={
            props.og_image || "https://store.kavishala.com/images/post_og.png"
          }
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta
          property="og:image:alt"
          content={
            props.og_image || "https://store.kavishala.com/images/post_og.png"
          }
        />
        <meta
          property="article:publisher"
          content={props.author_facebook || "Kavishala"}
        />
        <meta property="article:section" content={props.category} />
        <meta property="article:published_time" content={props.published_on} />
        {/* TWITTER META TAGS */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={props.og_description} />

        <meta
          name="twitter:title"
          content={
            props.og_title
              ? `${props.og_title.slice(0, 110)}...`
              : "Kavishala Academy"
          }
        />
        <meta name="twitter:site" content="@kavishalaAcademy" />
        <meta
          name="twitter:image"
          content={
            props.og_image || "https://store.kavishala.com/images/post_og.png"
          }
        />
        <meta
          name="twitter:creator"
          content={props.author_twitter || "@kavishalaAcademy"}
        />
      </Helmet>
    </div>
  );
};

export default SEO;
