import React, { useState } from "react";
import ItemCard from "../../components/Cards/ItemCard";
import SkeletonCard from "../../components/Cards/SkeletonCard";
import Pagination from "@mui/material/Pagination";
import { useSelector } from "react-redux";
import { setPage, setListingData2 } from "../../reducer/ListingReducer";
import { useDispatch } from "react-redux";
import NoImage from "./../../images/Cards/no-image.svg";

const Loader = () => {
  return Array(4)
    .fill()
    .map((e, i) => <SkeletonCard key={i} />);
};

export default function ListingCardContainer() {
  const dispatch = useDispatch();

  const { page, loading, listingData, listingData2 } = useSelector(
    (state) => state.Listing
  );

  const [totalCount, setTotalCount] = useState(1);
  const handlePageChange = () => {
    setTotalCount(page);
  };

  var count = Math.ceil(listingData.length / 16);

  return (
    <>
      <div className="listing-card-container">
        {!loading ? (
          <Loader />
        ) : (
          listingData?.map((data) => {
            return (
              <ItemCard
                key={data?.id}
                id={data?.id}
                image={data?.image || NoImage}
                price={data?.price}
                discountPrice={data?.discount_price}
                name={data?.name}
                rating={data?.rating}
                slug={data?.slug}
              />
            );
          })
        )}
      </div>
      <div className="listing-pegintion-container">
        {/* {!loading && ( */}
        <Pagination
          onChange={(e, v) => dispatch(setPage(v))}
          count={count}
          size="small"
          page={page}
          variant="outlined"
          shape="rounded"
        />
        {/* )} */}
      </div>
    </>
  );
}
