import React, { useEffect, useState } from "react";
import Check from "../StyledComponent/Check";
import cross from "./../../images/Cards/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  removefromCart,
  removeItemFromCart,
  updateCartItemQty,
} from "../../actions/Cart";

export default function CartItemCard({ checked, handleCheckBox, item }) {
  const dispatch = useDispatch();
  const { cartId } = useSelector((state) => state.Cart);
  const [loading, setLoading] = useState(false);

  const updateQty = (type) => {
    let qty = item?.product_count;

    if (type === "ADD") {
      qty += 1;
    }

    if (type === "SUB") {
      qty -= 1;
    }

    let data = {
      // basket_id: cartId,
      product_id: item?.product?.id,
      count: qty,
    };
    var products = [];
    setLoading(true);
    dispatch(updateCartItemQty(data, setLoading));
  };

  const removeItem = () => {
    let data = {
      product_id: item?.product?.id,
    };

    dispatch(removeItemFromCart(data, setLoading));
  };

  return (
    <>
      <div className="cart-item-card">
        <div
          onClick={() =>
            handleCheckBox(
              item.product?.name,
              !checked,
              item?.product.id,
              item?.product_count,
              item?.product?.discount_price
            )
          }
          className="div-1"
        >
          <Check checked={checked} />
        </div>

        <div className="div-2">
          <img className="item-img" src={item?.product?.image} alt="" />
        </div>

        <div className="div-3">
          <div className="info">
            <h1>{item?.product?.name}</h1>
            <p>UPC : {item?.product?.attribute[0]?.upc} </p>
          </div>
        </div>

        <div className="div-4">
          <div className="btn-group">
            <button
              onClick={() => {
                if (item.product_count === 1) {
                  removeItem();
                } else updateQty("SUB");
              }}
              className="btn-3"
            >
              -
            </button>
            <button className="btn-2">{item?.product_count}</button>
            <button onClick={() => updateQty("ADD")} className="btn-1">
              +
            </button>
          </div>
        </div>

        <div className="div-5">
          <div className="cross">
            <div onClick={removeItem}>
              <img src={cross} alt="" />
            </div>
          </div>
        </div>
        <div className="div-6">
          <div className="price">
            <p>₹ {item?.product?.discount_price * item?.product_count} </p>
          </div>
        </div>
      </div>
      {/* mobile responsive */}
      <div className="mob-cart-item-card">
        <div className="mob-cart-item-card-right">
          <div className="div-2">
            <div
              onClick={() =>
                handleCheckBox(
                  item.product?.name,
                  !checked,
                  item?.product.id,
                  item?.product_count,
                  item?.product?.discount_price
                )
              }
              className="div-1"
            >
              <Check checked={checked} />
            </div>
            <img className="item-img" src={item?.product?.image} alt="" />
          </div>

          <div className="div-3">
            <div className="info">
              <h1>{item?.product?.name}</h1>
              <p>UPC : {item?.product?.attribute[0]?.upc} </p>
            </div>
          </div>
        </div>
        <div className="mob-cart-item-card-left">
          <div className="div-4">
            <div className="btn-group">
              <button onClick={() => updateQty("ADD")} className="btn-1">
                +
              </button>
              <button className="btn-2">{item?.product_count}</button>
              <button
                onClick={() => {
                  if (item.product_count === 1) {
                    removeItem();
                  } else updateQty("SUB");
                }}
                className="btn-3"
              >
                -
              </button>
            </div>
          </div>

          <div className="div-5">
            <div className="cross">
              <div onClick={removeItem}>
                <img src={cross} alt="" />
              </div>
            </div>
          </div>
          <div className="div-6">
            <div className="price">
              <p>₹ {item?.product?.discount_price * item?.product_count} </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
