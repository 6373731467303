import React, { useEffect } from "react";
import DownArrow from "../../images/Listing/DownArrow.svg";
import Slider from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { setListingData, setPage } from "../../reducer/ListingReducer";
import { getProductRangeListing } from "../../actions/Listing";

export default function PriceRange() {
  const dispatch = useDispatch();
  const [value2, setValue2] = React.useState([0, 2000]);
  const minDistance = 500;
  const { listingData, page } = useSelector((state) => state.Listing);

  useEffect(() => {
    let temp = [...listingData];
    temp?.filter(function (x) {
      const min = x?.image_price_partner?.price;
      // const max = x?.image_price_partner?.price;

      return (
        // x?.image_price_partner?.price >= value2[0] &&
        // x?.image_price_partner?.price <= value2[1]
        min >= value2[0] && min <= value2[1]
        // min && max
      );
    });

    // dispatch(setListingData(temp));
    dispatch(getProductRangeListing(value2));
  }, [value2]);

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 10000 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }
  };

  return (
    <div className="listing-price-range">
      <div className="header">
        <h1>Price Range</h1>
        <img src={DownArrow} alt="" />
      </div>

      <div>
        <Slider
          min={0}
          step={100}
          max={10000}
          value={value2}
          onChange={handleChange2}
          valueLabelDisplay="auto"
          sx={{
            color: "#FF3861",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 15,
              height: 15,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
          }}
        />
      </div>
      <div className="range-amount">
        <p>{`₹ ${value2[0]}`}</p>
        <p>{`₹ ${value2[1]}`}</p>
      </div>
    </div>
  );
}
