import React, { useEffect, useState } from "react";
import { getHomeBrands } from "../../actions/Home";
import CarouselCard from "../../components/Cards/CarouselCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import MobileHomeCarosel from "./MobileHomeCarosel";
export default function HomeCarousel() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    getHomeBrands(setData, setLoading);
  }, []);

  return (
    <>
      <div className="Desktop_carousel">
        <div className="home-carousel-contianer">
          <div className="header">
            <h1>Brand Store</h1>
            <div className="right">
              {Array(data?.length)
                .fill()
                .map((data, index) => (
                  <>
                    <h1
                      onClick={() => setSelected(index)}
                      id={selected === index ? "selected" : ""}
                    >{`0${index + 1}`}</h1>
                    <div className="divider"></div>
                  </>
                ))}
            </div>
          </div>
          <Carousel
            centerMode
            showIndicators={false}
            // centerSlidePercentage={80}
            autoPlay
            selectedItem={selected}
            // infiniteLoop
            showStatus={false}
            showArrows={false}
            onChange={(e) => setSelected(e)}
          >
            {data?.map((data) => (
              <CarouselCard data={data} key={data?.id} />
            ))}
          </Carousel>
        </div>
      </div>
      <div className="mobileHomeCarousel">
        <MobileHomeCarosel />
      </div>
    </>
  );
}
