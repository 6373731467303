import React, { useState } from "react";
import SocialRegiser from "../../components/SocialRegister/SocialRegiser";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import TextInput from "../../components/StyledComponent/TextInput";
import PasswordInput from "../../components/StyledComponent/PasswordInput";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserLogin } from "../../reducer/UserReducer";
import { login } from "../../actions/User";
import LoginFooter from "./LoginFooter";
export default function LoginContainer({ setToast }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [loadingCTA, setLoadingCTA] = useState(false);
  const { cartId, cartItems } = useSelector((state) => state.Cart);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingCTA(true);
    setTimeout(() => {
      setLoadingCTA(false);
    }, 4000);
    const userData = {
      user: {
        email,
        password,
      },
    };

    dispatch(login(userData, setLoadingCTA, setToast, history, cartId));
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <SocialRegiser />
      <form onSubmit={handleSubmit}>
        <TextInput
          title="Email or Phone Number"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <PasswordInput
          title="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PrimaryCTA text="Login" type="submit" loading={loadingCTA} />

        <h2 onClick={() => history.push("/forget-password")}>
          Forgot Password?
        </h2>
        <div className="login_footer_container">
          <LoginFooter />
        </div>
        <h3>
          By signing in, you agree to Kavishala Store’s{" "}
          <span> Terms and Conditions.</span>
        </h3>
      </form>
    </div>
  );
}
