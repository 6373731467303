import Api from "../core/Api";
import { setToast } from "../reducer/AppReducer";

export const getProductInfo = (id, setData, setLoading) => {
  Api.getProductData(id)
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
export const getProductReviwes = (
  id,
  setReviewData,
  setLoading,
  setRefetch
) => {
  Api.getProductReview(id)
    .then((res) => {
      if (res?.status === 200) {
        setReviewData(res?.data);
        setLoading(false);
        setRefetch();
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
export const getProductReviewsCount = (
  id,
  setReviewCount,
  setLoading,
  setRefetch
) => {
  Api.getProductReviewCount(id)
    .then((res) => {
      console.log("lkjhgvb", res);
      if (res?.status === 200) {
        setReviewCount(res?.data);
        setLoading(false);
        setRefetch();
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
export const addReview = (data, setLoading, resetFields, setRefetch) => {
  return (dispatch) => {
    Api.addProductReview(data)
      .then((res) => {
        if (res?.data === 200) {
          setLoading(false);
          resetFields();
          setRefetch();
          dispatch(
            setToast({ open: true, isSuccess: true, msg: res?.data?.msg })
          );
        } else {
          resetFields();
          dispatch(
            setToast({ open: true, isSuccess: false, msg: res?.data?.msg })
          );
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};
