import Api from "../core/Api";

export const getMyOrderData = (setData, setLoading) => {
  Api.getOrder()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};
export const getMySitemap = () => {};
