import React from "react";

export default function WishListHeader() {
  return (
    <div className="wishlist-header">
      <div className="left">
        <h1>Description</h1>
      </div>
      <div className="right">
        <h1>Remove</h1>
        <h1>Price</h1>
      </div>
    </div>
  );
}
