import React, { useState, useEffect } from "react";
import {
  getProductReviwes,
  getProductReviewsCount,
} from "../../actions/ProductDetails";
import AddReview from "./AddReview";
import ReviewCard from "./ReviewCard";
import { useSelector } from "react-redux";

const Bar = ({ index, width, count }) => {
  return (
    <div className="bar">
      <h1>{index}</h1>
      <div className="bar-line-container">
        <div className="bar-line" style={{ width: `${width}%` }}></div>
      </div>
      <p>{count}</p>
    </div>
  );
};

const Review = ({ data }) => {
  console.log("data", data);
  return (
    <div className="review">
      <h1>Review</h1>
      <div className="container">
        <h2>{data?.Reviews}</h2>
        <div className="divider"></div>
        <div className="bar-container">
          <Bar
            index={5}
            width={parseInt((data?.five_stars / data?.Reviews) * 100)}
            count={data?.five_stars}
          />
          <Bar
            index={4}
            width={parseInt((data?.four_stars / data?.Reviews) * 100)}
            count={data?.four_stars}
          />
          <Bar
            index={3}
            width={parseInt((data?.three_stars / data?.Reviews) * 100)}
            count={data?.three_stars}
          />
          <Bar
            index={2}
            width={parseInt((data?.two_stars / data?.Reviews) * 100)}
            count={data?.two_stars}
          />
          <Bar
            index={1}
            width={parseInt((data?.one_stars / data?.Reviews) * 100)}
            count={data?.one_stars}
          />
        </div>
      </div>
    </div>
  );
};

export default function Reviews({ id }) {
  const [reviewData, setReviewData] = useState([]);
  const [reviewCount, setReviewCount] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getProductReviwes(id, setReviewData, setLoading);
    getProductReviewsCount(id, setReviewCount, setLoading);
  }, [refetch]);

  return (
    <div className="review-container">
      <Review data={reviewCount} setRefetch={(e) => setRefetch(!refetch)} />
      {reviewData?.map((data) => (
        <ReviewCard
          data={data}
          key={data?.id}
          setRefetch={(e) => setRefetch(!refetch)}
        />
      ))}
      {isLogin && (
        <AddReview id={id} setRefetch={(e) => setRefetch(!refetch)} />
      )}
    </div>
  );
}
