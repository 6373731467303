import React from "react";

export default function PaymentFailedContainer() {
  return (
    <div className="payment-box">
      <h1 className="payment-txt">Payment </h1>
      <div className="payment-success-container">
        <h1>Payment Failed</h1>
      </div>
    </div>
  );
}
