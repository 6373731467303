import React, { useEffect, useState } from "react";
import LogoTopBar from "../../components/TopBar/LogoTopBar";
import ResetPasswordContainer from "./Container";
import Toast from "../../components/Toast";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import SEO from "../../SEO";
export default function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  let params = queryString.parse(location?.search);

  useEffect(() => {
    if (params?.token) {
      return;
    } else {
      history.push("/login");
    }
  }, []);

  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  return (
    <div className="reset-password-screen">
      <SEO
        title={`Reset Password | Kavishala Store `}
        og_url={"/change-password"}
        og_type="e-commerce"
        og_title={`Reset Password | Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <LogoTopBar />
      <ResetPasswordContainer token={params?.token} setToast={setToast} />
      <Toast
        data={toast}
        handleClose={() =>
          setToast({
            open: false,
            isSuccess: false,
            msg: "",
          })
        }
      />
    </div>
  );
}
