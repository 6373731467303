import React from "react";
import completed from "./../../images/Cards/completed.svg";

export default function MyOrderCard({ data }) {
  return (
    <div className="myorder-card">
      <div className="left">
        <img className="item-img" src={data?.product?.image} alt="" />
        <div className="info">
          <h1>{data?.product?.name}</h1>
          <p>UPC : {data?.product?.attribute[0]?.upc} </p>
        </div>
        <div className="qty">
          <h1>{data?.product_count}</h1>
        </div>
      </div>
      <div className="right">
        <div className="status">
          {/* <h1>{data?.product?.order_status}</h1> */}
          {/* <img src={completed} alt="" /> */}
        </div>
        <div className="price">
          <p>₹ {data?.product?.discount_price * data?.product_count} </p>
        </div>
      </div>
    </div>
  );
}
