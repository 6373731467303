import Api from "../core/Api";
export const getFeatureProducts = (setData, setLoading) => {
  Api.getFeatureProduct()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
export const getProductsListData = (
  setBooks,
  setCloth,
  setAll,
  setRenderCards,
  setLoading
) => {
  Api.getProductList()
    .then((res) => {
      if (res?.status === 200) {
        try {
          setBooks(res?.data?.Books);
          setCloth(res?.data?.Tshirt);
          setAll([...res?.data?.Tshirt, ...res?.data?.Books]);
          setRenderCards([...res?.data?.Tshirt, ...res?.data?.Books]);
          setLoading(false);
        } catch (error) {
          setBooks(res?.data?.Books);
          setAll([...res?.data?.Tshirt, ...res?.data?.Books]);
          setRenderCards([...res?.data?.Tshirt, ...res?.data?.Books]);
          setLoading(false);
        }
      }
    })
    .catch((e) => {});
};

export const getHomeBrands = (setData, setLoading) => {
  Api.getHomePageBrands()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};
export const getHomeOffer = (setData, setLoading) => {
  Api.getHomePageOffer()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data?.results);
        setLoading(false);
      }
    })
    .catch((e) => {});
};

export const getHomeCover = (setCover, setLoading) => {
  Api.getHomePageCover()
    .then((res) => {
      if (res?.status === 200) {
        // let tdata = {
        //   image: res?.data?.results[0]?.image,
        //   mobileImage: res?.data?.results[0]?.image_mobile,
        //   title: res?.data?.results[0]?.cover_title,
        //   desc: res?.data?.results[0]?.content,
        // };
        setCover(res?.data);

        setLoading(false);
      }
    })
    .catch((e) => {});
};

export const getHomeFaq = (setData, setLoading) => {
  Api.getFaq()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data?.results);
        setLoading(false);
      }
    })
    .catch((e) => {});
};

export const getHomePartners = (setData, setLoading) => {
  Api.getPartners()
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};
export const getHomeCatogery = (setCategory, setLoading) => {
  Api.getCategory()
    .then((res) => {
      if (res?.status === 200) {
        setCategory(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};
