import React, { useState } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { proccedToCheckout } from "../../actions/Cart";
import { setProccedToCheckOut } from "../../reducer/CartReducer";
import { useDispatch } from "react-redux";
import { setToast } from "../../reducer/AppReducer";

export default function CartCheckout({ total, selected, products }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const { cartItems, cartId, proccedToCheckOut } = useSelector(
    (state) => state?.Cart
  );

  const handleCheckout = () => {
    if (!isLogin) {
      history.push("/login");
      return;
    }

    let tempSelected = Object.keys(selected).filter(
      (i) => selected[i] === true
    );

    let a = [];

    // for (let i = 0; i < cartItems.length; i++) {
    //   for (let k = 0; k < tempSelected.length; k++) {
    //     if (cartItems[i]?.product == tempSelected[k]) {
    //       a.push(cartItems[i]);
    //     }
    //   }
    // }
    // console.log("kjhgvgbhj", a);
    // let b = cartItems.filter((el) => !a.includes(el));
    // console.log("ranjsyhh", b);

    if (tempSelected?.length === 0) {
      dispatch(
        setToast({ open: true, isSuccess: true, msg: "please select an item" })
      );
    } else {
      dispatch(setProccedToCheckOut(products));
      history.push("/shipping-address");
    }

    // dispatch(proccedToCheckout(data, setLoading, history));
  };

  return (
    <div className="cart-checkout">
      <div className="container">
        <div className="total">
          <p>Total</p>
          <p>
            <span>₹ {total}</span>
          </p>
        </div>
        {total > 0 && (
          <PrimaryCTA
            loading={loading}
            onClick={() => handleCheckout()}
            text="Checkout"
          />
        )}
      </div>
    </div>
  );
}
