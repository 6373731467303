import React, { useEffect, useState } from "react";
import Api from "../../core/Api";
const Order = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const loadData = () => {
      Api.orderDetails().then((res) => {
        setData(res?.data);
      });
    };
    loadData();
  }, []);
  useEffect(() => {
    const loadData = () => {
      Api.stockDetails().then((res) => {});
    };
    loadData();
  }, []);

  return (
    <div className="order_conatiner">
      <ul className="orderList">
        <li className="oreders_details">Order No</li>
        <li className="oreders_details">Product Image</li>
        <li className="oreders_details">Product Name</li>
        <li className="oreders_details">Price</li>
        <li className="oreders_details">Ordered</li>
        <li className="oreders_details">Shipping Method</li>
      </ul>
      {data?.map((value) => {
        return (
          <ul className="orderList">
            <li className="oreders_details">{value?.number}</li>
            <li className="oreders_details">
              <img
                src={value?.product?.image_price_partner?.image_url}
                alt={value?.product?.image_price_partner?.image_url}
                className="order_name"
              />
            </li>
            <li className="oreders_details">{value?.product?.title}</li>
            <li className="oreders_details">
              {value?.product?.image_price_partner?.price}
            </li>
            <li className="oreders_details">{value?.quantity}</li>
            <li className="oreders_details">{value?.shipping_method}</li>
          </ul>
        );
      })}
    </div>
  );
};

export default Order;
