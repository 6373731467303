import React, { useState, useEffect } from "react";
import heart from "./../../images/Cards/heart.svg";
import Starfilled from "./../../images/Cards/star-filled.svg";
import Star from "./../../images/Cards/star.svg";
import NoImage from "./../../images/Cards/no-image.svg";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart, buyNowProduct } from "../../actions/Cart";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router";
import { proccedToCheckout } from "../../actions/Cart";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";

export default function ProductCard({ data }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cartId } = useSelector((state) => state.Cart);
  const { cartItems } = useSelector((state) => state?.Cart);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [inCart, setInCart] = useState(false);
  const [qty, setQty] = useState(1);
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const handleClose = () => {};

  const path = window.location.pathname;
  localStorage.setItem("path", path);

  useEffect(() => {
    let index = cartItems?.findIndex((e) => e.product?.id === data?.id);

    if (index !== -1) {
      setInCart(true);
    } else {
      setInCart(false);
    }
  }, [cartItems, data?.id]);

  const handleAddToCart = () => {
    let tempData = {
      // basket_id: cartId,
      product_id: data?.id,
      count: qty,
    };
    setLoading(true);
    dispatch(addItemToCart(tempData, setLoading, handleClose));
  };

  const handleBuyNow = () => {
    if (!isLogin) {
      history.push("/login");
      return;
    }

    let temp = {
      product_id: data?.id,
      quantity: qty,
    };

    setLoading2(true);

    dispatch(buyNowProduct(temp, setLoading2, history));
  };

  return (
    <>
      <div className="product-card">
        <div className="left">
          <div className="large-img">
            {data?.product_image?.length > 0 ? (
              <img src={data?.product_image[0]?.image} alt="" />
            ) : (
              <img src={NoImage} alt="" />
            )}
          </div>

          <div className="small-img">
            {data?.product_image?.length > 0 &&
              data?.product_image
                ?.slice(0, 2)
                .map((e, index) => <img src={e?.image} alt="" />)}
          </div>
        </div>
        <div className="right">
          <h1>{data?.name}</h1>
          <div className="rating-status">
            <div className="rating">
              {Array(data?.rating)
                .fill()
                .map((e, _i) => (
                  <img src={Starfilled} key={_i} alt="" />
                ))}
              {Array(5 - data?.rating)
                .fill()
                .map((e, _i) => (
                  <img src={Star} key={_i} alt="" />
                ))}
            </div>
            <div
              className="status"
              style={{
                background: data?.stock_num === 0 ? "red" : null,
              }}
            >
              {data?.stock_num > 0 ? <p>In Stock</p> : <p>Out of Stock</p>}
            </div>
          </div>
          <div className="price">
            <p>₹ {data?.discount_price}</p>
            <p>
              <span>₹ {data?.price}</span>
            </p>
          </div>

          <div className="btn-group-1">
            <button
              onClick={() => qty !== 1 && setQty(qty - 1)}
              className="btn-3"
            >
              -
            </button>
            <button className="btn-2">{qty}</button>
            <button
              onClick={() => qty + 1 <= data?.stock_num && setQty(qty + 1)}
              className="btn-1"
            >
              +
            </button>
          </div>

          <div className="description">
            <p dangerouslySetInnerHTML={{ __html: data?.discription }} />
          </div>
        </div>
      </div>
      <div className="product-card1">
        <div className="right">
          <div className="btn-group-2">
            {inCart ? (
              <button className="btn-1" onClick={() => history.push("/cart")}>
                Go to Cart
              </button>
            ) : (
              <button
                disabled={data?.stock_num === 0}
                className="btn-1"
                onClick={handleAddToCart}
              >
                {loading ? <CircularProgress size={40} /> : "Add to Cart"}
              </button>
            )}
            {!inCart && (
              <PrimaryCTA
                disabled={data?.stock_num === 0}
                onClick={handleBuyNow}
                text="Buy Now"
                style={{ width: "130px" }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
