import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import SearchIcon from "@material-ui/icons/Search";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { useHistory } from "react-router-dom";
import user from "../../images/Topbar/user.svg";
import downArrow from "../../images/Topbar/downArrow.svg";
import { useSelector, useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import menu from "../../images/Topbar/menu.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { resetUserReducer } from "../../reducer/UserReducer";
import { resetCartReducer } from "../../reducer/CartReducer";
const MobileNavifation = () => {
  const dispatch = useDispatch();
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const { cartId, cartItems } = useSelector((state) => state.Cart);
  const { wishlistItems } = useSelector((state) => state.WishList);
  const [value, setValue] = React.useState("recents");
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(resetUserReducer());
    dispatch(resetCartReducer());
    localStorage.removeItem("store_token");
    localStorage.clear();
    history.push("/login");
  };

  return (
    <Box sx={{ width: "100%" }} className="mobileNavigation">
      <BottomNavigation
        sx={{ width: "100%" }}
        value={value}
        onChange={handleChange}
      >
        <div>
          <BottomNavigationAction
            label="Search"
            value="Search"
            icon={<SearchIcon className="icons" />}
            onClick={() => history.push("/search")}
          />
        </div>
        <div className="bottom_navigation_value">
          <BottomNavigationAction
            label="Favorites"
            value="Favorites"
            icon={<FavoriteBorderOutlinedIcon className="icons" />}
            onClick={() => history.push("/wishlist")}
          />
          {localStorage?.getItem("store_token") ? (
            <span
              className={wishlistItems?.length === 0 ? "" : "CartItems_Value"}
            >
              {wishlistItems?.length === 0 ? "" : wishlistItems?.length}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="bottom_navigation_value">
          <BottomNavigationAction
            label="Cart"
            value="Cart"
            icon={<ShoppingCartOutlinedIcon className="icons" />}
            onClick={() => history.push("/cart")}
          />
          <span className={cartItems?.length === 0 ? "" : "CartItems_Value"}>
            {cartItems?.length === 0 ? "" : cartItems?.length}
          </span>
        </div>
        {isLogin ? (
          <div onClick={handleClick} className="user-menu">
            <BottomNavigationAction
              label={userInfo?.fullname}
              value={userInfo?.fullname}
              icon={<AccountCircleOutlinedIcon className="icons" />}
              // onClick={() => history.push("/login")}
            />
          </div>
        ) : (
          <BottomNavigationAction
            label="Profile"
            value="Profile"
            icon={<AccountCircleOutlinedIcon className="icons" />}
            onClick={() => history.push("/login")}
          />
        )}
      </BottomNavigation>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="menu_bottom_navigation"
      >
        <MenuItem
          onClick={() => {
            history.push("/user-info");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/account");
            handleClose();
          }}
        >
          My account
        </MenuItem>
        {localStorage.getItem("isPartner") === "true" ? (
          <MenuItem
            onClick={() => {
              history.push("/vendor-dashboard");
              handleClose();
            }}
          >
            Vendor Dashboard
          </MenuItem>
        ) : (
          ""
        )}

        <MenuItem
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MobileNavifation;
