import React from "react";
import { useHistory } from "react-router";
import { deleteUserAddress } from "../../actions/User";

export default function CheckOutAddressCard({ data }) {
  const history = useHistory();

  return (
    <div className="checkout-address-card">
      <div className="header">
        <h1>Shipping To</h1>
      </div>

      <div className="container">
        <p>
          Name :<span>{`${data?.name}`},</span>
        </p>
        <p>
          Phone : <span>{data?.phone} </span>
        </p>
        <p>
          Line 1 : <span> {data?.address_line_1} </span>{" "}
        </p>
        <p>
          Line 2 : <span> {data?.address_line_2} </span>{" "}
        </p>
        <p>
          Line 3 : <span> {data?.address_line_3} </span>{" "}
        </p>
        <p>
          City : <span> {data?.city} </span>{" "}
        </p>
        <p>
          State : <span> {data?.state} </span>{" "}
        </p>
        <p>
          Country : <span> {"India"} </span>{" "}
        </p>
        <p>
          Pin : <span> {data?.pincode} </span>{" "}
        </p>
      </div>
    </div>
  );
}
