import React, { useState } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import PasswordInput from "../../components/StyledComponent/PasswordInput";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../actions/User";

export default function SignUpContainer({ token, setToast }) {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [loadingCTA, setLoadingCTA] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8 || cnfPassword.length < 8) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Password must be of 8 Characters",
      });
      return;
    }
    if (password !== cnfPassword) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Password does not match with Confirm Password",
      });
      return;
    }

    setLoadingCTA(true);

    const userData = {
      password,
      token,
    };

    resetPassword(userData, setLoadingCTA, history, setToast);
  };

  return (
    <div className="reset-password-container">
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <PasswordInput
          title="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PasswordInput
          title="Confirm Password"
          value={cnfPassword}
          onChange={(e) => setCnfPassword(e.target.value)}
          required
        />
        <PrimaryCTA type="submit" text="Next" loading={loadingCTA} />
      </form>
    </div>
  );
}
