import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isLogin: false,
  userInfo: undefined,
  userAddress: [],
  selectedIndex: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {
    setUserLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserAddress: (state, action) => {
      state.userAddress = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
    resetUserReducer: (state) => (state = defaultState),
  },
});

export const {
  setUserLogin,
  setUserInfo,
  setUserAddress,
  setSelectedIndex,
  resetUserReducer,
} = userSlice.actions;
export default userSlice.reducer;
