import React, { useState } from "react";
import SocialRegiser from "../../components/SocialRegister/SocialRegiser";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import TextInput from "../../components/StyledComponent/TextInput";
import PasswordInput from "../../components/StyledComponent/PasswordInput";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../actions/User";

export default function SignUpContainer({ setToast }) {
  const dispatch = useDispatch();
  const { cartId, cartItems } = useSelector((state) => state.Cart);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loadingCTA, setLoadingCTA] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phone?.length !== 10) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Phone Number must be of 10 Digits",
      });

      return;
    }

    if (password.length < 8 || cnfPassword.length < 8) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Password must be of 8 Characters",
      });
      return;
    }
    if (password !== cnfPassword) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Password does not match with Confirm Password",
      });
      return;
    }
    setLoadingCTA(true);

    setTimeout(() => {
      setLoadingCTA(false);
    }, 4000);
    const userData = {
      user: {
        email,
        password,
        username: name,
        phone: phone,
      },
    };

    dispatch(signUp(userData, setLoadingCTA, setToast, history, cartId));
  };

  return (
    <div className="signup-container">
      <h1>Create Account</h1>
      <SocialRegiser />
      <form onSubmit={handleSubmit}>
        <TextInput
          title="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextInput
          title="Email"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextInput
          title="Phone Number"
          type="number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <PasswordInput
          title="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <PasswordInput
          title="Confirm Password"
          value={cnfPassword}
          onChange={(e) => setCnfPassword(e.target.value)}
          required
        />
        <PrimaryCTA text="Sign Up" type="submit" loading={loadingCTA} />

        <div className="flex-center">
          <div className="footer-divider"></div>
        </div>

        <h3>
          Already have an account?{" "}
          <span onClick={() => history.push("/login")}> Sign In</span>
        </h3>
      </form>
    </div>
  );
}
