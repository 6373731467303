import React from "react";

export default function ProductInfo({ data }) {
  return (
    <div className="product-info">
      <h1>Product Info</h1>
      <div className="content">
        <div className="div-1">
          {data?.attribute?.map((data, index) => (
            <>
              <p>{data?.info}</p>
            </>
          ))}
        </div>
        <div className="div-2"> </div>
        <div className="div-3">
          {data?.attribute.map((data, index) => (
            <p>
              UPC : <span>{data?.upc}</span>{" "}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
