import Api from "../core/Api";

export const searchItem = (data, setResult, result, setIsEmpty, setLoading) => {
  Api.search(data)
    .then((res) => {
      setResult(res?.data);
      setLoading(false);
      setIsEmpty(false);
      // })

      if (res?.data?.count === 0) {
        setIsEmpty(true);
      }

      setResult(res?.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
