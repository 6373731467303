import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CheckBox from "../../components/StyledComponent/CheckBox";
import ExpandIcon from "../../images/Listing/Expand.svg";
import CollaspeIcon from "../../images/Listing/Collaspe.svg";
import {
  getListingProducts,
  getProductFilterListing,
} from "../../actions/Listing";

export default function CheckBoxGroup({ title, data }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({});
  const [expand, setExpand] = useState(false);
  // const handleCheckBox = (name, check) => {
  //   let temp = { ...checked, [name]: check };
  //   setChecked(temp);

  //   let tempData = {
  //     partner: [],
  //     product_category: [],
  //   };

  //   dispatch(getListingProducts(1, tempData));
  // };
  const handleCheckBox = (id, name, check) => {
    let temp = { ...checked, [name]: check };
    setChecked(temp);

    let tempData = {
      id: id,
    };

    getProductFilterListing(tempData);
    dispatch(getProductFilterListing(tempData));
  };
 
  return (
    <div className="listing-check-box-group">
      <div className="header" onClick={() => setExpand(!expand)}>
        <h1> {title}</h1>
        <img src={expand ? CollaspeIcon : ExpandIcon} alt="" />
      </div>

      {expand &&
        data?.map((data, key) => (
          <CheckBox
            label={data?.filter_values__name}
            id={data?.filter_values}
            onClick={() =>
              handleCheckBox(
                data?.filter_values,
                data?.filter_values__name,

                !checked[data?.filter_values__name]
              )
            }
            checked={checked[data?.filter_values__name]}
          />
        ))}
    </div>
  );
}
