import React, { useEffect, useState } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import WishListContiner from "./WishListContiner";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { fetchAllWishList } from "../../actions/WishList";
import { useDispatch } from "react-redux";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function WishList() {
  const dispatch = useDispatch();
  const [appDrawer, setAppDrawer] = useState(false);

  useEffect(() => {
    dispatch(fetchAllWishList());
  }, []);
  const path = window.location.pathname;
  localStorage.setItem("path", path);
  return (
    <>
      {" "}
      <SEO
        title={`Whishlist Item | Kavishala Store`}
        og_url={"/wishlist"}
        og_type="e-commerce"
        og_title={`Whishlist Item | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="wishlist">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="wishlist-container">
          <WishListContiner />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
      </div>
    </>
  );
}
