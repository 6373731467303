import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function DropDown({ title, list, ...rest }) {
  return (
    <div className="styled-drop-down">
      <p>{title} </p>

      <Select {...rest} fullWidth variant="standard" disableUnderline>
        {list?.map((data) => (
          <MenuItem value={data}>{data}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
