import React, { useState } from "react";
import expandIcon from "../../images/Details/expand.svg";
import collaspeIcon from "../../images/Details/collaspe.svg";
import heart from "./../../images/Cards/heart.svg";
import Starfilled from "./../../images/Cards/star-filled.svg";
import Star from "./../../images/Cards/star.svg";
import PrimaryCta from "../../components/StyledComponent/PrimaryCTA";
import { addReview } from "../../actions/ProductDetails";
import { useSelector, useDispatch } from "react-redux";

const StarInput = ({ count, setCount }) => {
  console.log("jhgvcvbn", count);
  return (
    <div className="add-review-rating-input">
      <div className="left">
        <h1>Rating</h1>
      </div>
      <div className="rating">
        {Array(count)
          .fill()
          .map((e, _i) => (
            <img
              onClick={() => setCount(_i + 1)}
              src={Starfilled}
              key={_i}
              alt=""
            />
          ))}
        {Array(5 - count)
          .fill()
          .map((e, _i) => (
            <img
              onClick={() => setCount(_i + 1 + count)}
              src={Star}
              key={_i}
              alt=""
            />
          ))}
      </div>
    </div>
  );
};

const AddReviewInput = ({ title, ...rest }) => {
  return (
    <div className="add-review-input">
      <div className="left">
        <h1>{title} </h1>
      </div>

      <input {...rest} />
    </div>
  );
};
const AddReviewTextArea = ({ title, ...rest }) => {
  return (
    <div className="add-review-input">
      <div className="left">
        <h1>{title} </h1>
      </div>

      <textarea {...rest} rows={4} />
    </div>
  );
};
const AddReviewSubmit = ({ ...rest }) => {
  return (
    <div className="add-review-submit">
      <div className="left"></div>
      <div className="right">
        <PrimaryCta {...rest} fullWidth text="Submit Your Review" />
      </div>
    </div>
  );
};

export default function AddReview({ id, setRefetch }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [heading, setHeading] = useState("");
  const [body, setBody] = useState("");

  const resetFields = () => {
    setHeading("");
    setCount(0);
    setBody("");
    setExpand(false);
  };

  const handeSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      product: id,
      rating: count,
      title: heading,
      body: body,
    };

    dispatch(addReview(data, setLoading, resetFields, setRefetch));
  };

  return (
    <div className="add-review">
      <div className="top">
        <h1>Write A Review</h1>
        <img
          onClick={() => setExpand(!expand)}
          src={!expand ? collaspeIcon : expandIcon}
          alt=""
        />
      </div>

      {expand && (
        <form onSubmit={(e) => handeSubmit(e)}>
          <StarInput count={count} setCount={setCount} />
          <AddReviewInput
            title="Name"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            required
          />
          <AddReviewTextArea
            title="Review"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
          />
          <AddReviewSubmit type="submit" />
        </form>
      )}
    </div>
  );
}
