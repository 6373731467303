import React, { useEffect } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { useHistory } from "react-router-dom";
import Api from "../../core/Api";
import Carousel from "react-multi-carousel";
import { getUserInfo } from "../../actions/User";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
export default function Layout1({ data }) {
  const { userInfo } = useSelector((state) => state.User);
  const history = useHistory();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const loadData = () => {
      Api.getUserData(userInfo?.slug)
        .then((res) => {
          localStorage.setItem("isPartner", res?.data?.partner);
        })
        .catch((error) => console.log("Error", error));
    };
    loadData();
  }, []);

  return (
    <Carousel
      responsive={responsive}
      arrows={true}
      swipeable={false}
      draggable={true}
      autoPlay={true}
      showDots={false}
      ssr={true}
      renderDotsOutside={true}
      infinite
      containerClass="carousel-container"
    >
      {data?.map((value) => (
        <div
          className="layout1"
          style={{
            backgroundImage: `url(${
              window.innerWidth > 480 ? value?.image : value?.image_mobile
            })`,
          }}
          onClick={() => window.open(value?.url)}
        >
          <div className="container">
            <div className="box">
              {/* <h1>{value?.cover_title}</h1>
              <p>{value?.desc}</p> */}
              <div className="cta-container">
                <PrimaryCTA
                  text="SHOP NOW"
                  onClick={() => history.push(`/catalogue`)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}
