import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import DownArrow from "../../images/Listing/DownArrow.svg";

export default function DropDownGroup({ title, data, handleFilterClick }) {
  const [visible, setVisible] = useState(false);

  return (
    <div className="custom-drop-down">
      <div onClick={() => setVisible(!visible)} className="heading">
        <h1>{title}</h1>
        <img src={DownArrow} alt="" />
      </div>

      <div className="content" style={{ display: visible ? "block" : "none" }}>
        {data?.map((data, index) => (
          <MenuItem
            onClick={() => {
              setVisible(false);
              handleFilterClick(index);
            }}
            key={index}
          >
            <p className="menuItem">{data}</p>
          </MenuItem>
        ))}
      </div>
    </div>
  );
}
