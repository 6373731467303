import React, { useState, useEffect } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import PersonalDetailsContainer from "./PersonalDetailsContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { getUserInfo } from "../../actions/User";
import { useSelector } from "react-redux";
import Toast from "../../components/Toast";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function PersonalDetails() {
  const { userInfo } = useSelector((state) => state.User);
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  const [appDrawer, setAppDrawer] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserInfo(userInfo?.username, setData, setLoading);
  }, [refetch, userInfo?.username]);
  console.log("userinfo", userInfo);
  return (
    <>
      <SEO
        title={`${userInfo?.username} | Kavishala Store`}
        og_url={"/user-info"}
        og_type="e-commerce"
        og_title={`${userInfo?.username}| Kavishala Store`}
        og_description={"Kavishala User"}
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="personal-detail">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="personal-detail-container">
          <PersonalDetailsContainer
            slug={userInfo?.username}
            setToast={setToast}
            data={data}
            refetch={() => setRefetch(!refetch)}
          />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <Toast
          data={toast}
          handleClose={() =>
            setToast({
              open: false,
              isSuccess: false,
              msg: "",
            })
          }
        />

        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
      </div>
    </>
  );
}
