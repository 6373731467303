import React, { useState } from "react";
import LogoTopBar from "../../components/TopBar/LogoTopBar";
import LoginContainer from "./Container";
import LoginFooter from "./LoginFooter";
import Toast from "../../components/Toast";
import SEO from "../../SEO";
export default function Login() {
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });
  return (
    <div className="login-screen">
      <SEO
        title={`Login | Kavishala Store `}
        og_url={"/login"}
        og_type="e-commerce"
        og_title={`Login | Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <LogoTopBar />
      <LoginContainer setToast={setToast} />
      {/* <LoginFooter /> */}
      <Toast
        data={toast}
        handleClose={() =>
          setToast({
            open: false,
            isSuccess: false,
            msg: "",
          })
        }
      />
    </div>
  );
}
