import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DashBoardtabs from "./DashBoardtabs";

var coins = "/images/coin.png";
var coin = "/images/coin.svg";
const TopBar = ({ selected, setSelected, data }) => {
  return (
    <div className="topbar_container">
      <div>
        <DashBoardtabs selected={selected} setSelected={setSelected} />
      </div>
    </div>
  );
};

export default TopBar;
