import React, { useEffect, useState } from "react";
import CartItemCard from "../../components/Cards/CartItemCard";
import BackArrow from "../../images/Modal/BackArrow.png";
import CartCheckout from "./CartCheckout";
import { useHistory } from "react-router-dom";
import CartHeader from "./CartHeader";
import { useSelector } from "react-redux";

export default function CartContinerr() {
  const history = useHistory();
  const { cartItems } = useSelector((state) => state?.Cart);
  const [selected, setSelected] = useState({});
  const [selected2, setSelected2] = useState({});
  const [totalAmt, setTotalAmt] = useState(0);
  const [products, setProducts] = useState([]);
  const handleCheckBox = (name, check, item, count, amount) => {
    // products.push({
    //   product_id: item?.product?.id,
    //   count: item?.product_count,
    // });

    setProducts([
      ...products,
      { product_id: item, count: count, amount: amount },
    ]);
    let temp = { ...selected, [name]: check };
    let temp2 = { ...selected2, [item]: check };
    setSelected(temp);
    // setSelected2(temp2);
  };

  useEffect(() => {
    calculateTotal();
  }, [cartItems]);
  const calculateTotal = () => {
    let totalPrice = cartItems?.reduce((a, b) => (a += parseFloat(b.total)), 0);
    setTotalAmt(totalPrice?.toFixed(2));
  };

  var total = 0;
  return (
    <div className="cart-box">
      <div className="back-btn">
        <img
          className="backArrow"
          src={BackArrow}
          alt=""
          onClick={() => history.push("/")}
        />
        <h1 className="your-cart">Your Shopping Cart</h1>
      </div>
      <CartHeader />
      <div>
        {cartItems?.map((item) => {
          total = total + item?.product?.discount_price * item?.product_count;
          return (
            <CartItemCard
              key={item?.id}
              checked={selected[item?.product?.name]}
              handleCheckBox={handleCheckBox}
              item={item}
              selected2={selected2}
            />
          );
        })}
        {/* {cartItems?.map((item) => {
          return (
            <CartItemCard
              key={item?.id}
              checked={selected[item?.product]}
              handleCheckBox={handleCheckBox}
              item={item}
            />
          );
        })} */}

        <CartCheckout total={total} selected={selected} products={products} />
      </div>
    </div>
  );
}
