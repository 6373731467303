import Api from "../core/Api";
import jwt_decode from "jwt-decode";
import {
  setUserAddress,
  setUserInfo,
  setUserLogin,
} from "../reducer/UserReducer";
import { setCartId, setCartItem } from "../reducer/CartReducer";

export const login = (data, setLoadingCTA, setToast, history, cartId) => {
  const path = localStorage?.getItem("path");

  return (dispatch) => {
    Api.login(data)
      .then((res) => {
        if (res.status === 200) {
          setLoadingCTA(false);
          localStorage.setItem("store_token", res?.data.token);
          var decoded = jwt_decode(res?.data.token);

          dispatch(setUserLogin(true));
          dispatch(setUserInfo(decoded));
          if (cartId) {
            dispatch(getCartItems(history));
          } else {
            history.push(`${path === null ? "/" : path}`);
          }
        } else if (res.status === 400) {
          setLoadingCTA(false);
          setToast({
            open: true,
            isSuccess: false,
            msg: res.data.non_field_errors[0],
          });
        }
      })
      .catch((error) => {
        setToast({
          open: true,
          isSuccess: false,
          msg: "A user with this email and password was not found.",
        });
      });
  };
};
export const socialsLogin = (
  data,
  setLoadingCTA,
  setToast,
  history,
  cartId
) => {
  const path = localStorage?.getItem("path");
  return (dispatch) => {
    Api.socialLogin(data)
      .then((res) => {
        if (res.data.status) {
          setLoadingCTA(false);
          localStorage.setItem("store_token", res?.data.token);
          dispatch(setUserLogin(true));
          dispatch(setUserInfo(res.data));
          if (cartId) {
            dispatch(getCartItems(history));
          } else {
            history.push(`${path === null ? "/" : path}`);
          }
        } else {
          setLoadingCTA(false);
          setToast({
            open: true,
            isSuccess: false,
            msg: res?.data?.msg,
          });
        }
      })
      .catch((error) => console.log("Error", error));
  };
};
export const twitterLogin = (data) => {
  Api.twiterLogin(data).then((res) => {});
};
export const signUp = (data, setLoadingCTA, setToast, history, cartId) => {
  const path = localStorage?.getItem("path");
  return (dispatch) => {
    Api.signup(data)
      .then((res) => {
        if (res?.status === 201) {
          setLoadingCTA(false);
          localStorage.setItem("store_token", res?.data.token);
          dispatch(setUserLogin(true));
          dispatch(setUserInfo(res.data));
          if (cartId) {
            dispatch(getCartItems(history));
          } else {
            history.push(`${path === null ? "/" : path}`);
          }
        } else {
          setLoadingCTA(false);
          setToast({
            open: true,
            isSuccess: false,
            msg: res?.data?.msg,
          });
        }
      })
      .catch((error) =>
        setToast({
          open: true,
          isSuccess: false,
          msg: "user with this email already exists",
        })
      );
  };
};

const getCartItems = (history) => {
  const path = localStorage?.getItem("path");
  return (dispatch) => {
    Api.getAllCartItems()
      .then((res) => {
        if (res?.status) {
          dispatch(setCartItem(res?.data?.user_cart));
          history.push(`${path}`);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };
};

export const forgetPassword = (data, setLoadingCTA, setToast, history) => {
  Api.forgetPassword(data)
    .then((res) => {
      if (res.data.status) {
        setToast({
          open: true,
          isSuccess: true,
          msg: res?.data?.msg,
        });

        setTimeout(() => {
          setLoadingCTA(false);
          history.push("/login");
        }, 4000);
      } else {
        setLoadingCTA(false);
        setToast({
          open: true,
          isSuccess: false,
          msg: res?.data?.msg,
        });
      }
    })
    .catch((error) => console.log("Error", error));
};
export const resetPassword = (data, setLoadingCTA, history, setToast) => {
  Api.resetPassword(data)
    .then((res) => {
      if (res.data.status) {
        setToast({
          open: true,
          isSuccess: true,
          msg: res?.data?.msg,
        });

        setTimeout(() => {
          setLoadingCTA(false);
          history.push("/login");
        }, 4000);
      } else {
        setLoadingCTA(false);
        setToast({
          open: true,
          isSuccess: false,
          msg: res?.data?.msg,
        });
      }
    })
    .catch((error) => console.log("Error", error));
};

export const getUserInfo = (slug, setData, setLoading) => {
  Api.getUserData(slug)
    .then((res) => {
      if (res.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((error) => console.log("Error", error));
};

export const updateUserInfo = (slug, data, setLoading, refetch) => {
  Api.updateUserData(slug, data)
    .then((res) => {
      if (res.status === 200) {
        setLoading(false);
        refetch();
      }
    })
    .catch((error) => console.log("Error", error));
};

export const changeUserPassword = (data, setLoading, setToast, resetData) => {
  Api.changePassword(data)
    .then((res) => {
      if (res.status) {
        resetData();
        setToast({
          open: true,
          isSuccess: true,
          msg: res?.data?.status,
        });
        setLoading(false);
      } else {
        setToast({
          open: true,
          isSuccess: false,
          msg: res?.data?.status,
        });
        setLoading(false);
      }
    })
    .catch((error) =>
      setToast({
        open: true,
        isSuccess: false,
        msg: error?.data?.msg,
      })
    );
};

export const getUserAddress = (setLoading) => {
  return (dispatch) => {
    Api.getAddress()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUserAddress(res?.data));
          setLoading(false);
        }
      })
      .catch((error) => console.log("Error", error));
  };
};
export const getUserAddressById = (id, setData, setLoading) => {
  Api.getAddressById(id)
    .then((res) => {
      if (res.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((error) => console.log("Error", error));
};

export const addNewAddress = (data, setLoading, setToast, history) => {
  Api.addAddress(data)
    .then((res) => {
      if (res.status === 201) {
        setToast({
          open: true,
          isSuccess: true,
          msg: "New Address Added",
        });

        setTimeout(() => {
          setLoading(false);
          history.goBack();
        }, 3000);
      } else {
        setToast({
          open: true,
          isSuccess: false,
          msg: "Something Went Wrong",
        });
        setLoading(false);
      }
    })
    .catch((error) => console.log("Error", error));
};
export const editUserAddress = (id, data, setLoading, setToast, history) => {
  Api.editAddress(id, data)
    .then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          isSuccess: true,
          msg: "Address Updated",
        });

        setTimeout(() => {
          setLoading(false);
          history.push("/address");
        }, 3000);
      } else {
        setToast({
          open: true,
          isSuccess: false,
          msg: "Something Went Wrong",
        });
        setLoading(false);
      }
    })
    .catch((error) => console.log("Error", error));
};

export const deleteUserAddress = (id, refetch, setToast) => {
  Api.deleteAddress(id)
    .then((res) => {
      if (res.status === 204) {
        setToast({
          open: true,
          isSuccess: true,
          msg: "Address Deleted",
        });
        setTimeout(() => {
          refetch();
        }, 2000);
      } else {
        setToast({
          open: true,
          isSuccess: false,
          msg: "Something Went Wrong",
        });
      }
    })
    .catch((error) => console.log("Error", error));
};
