import React, { useState } from "react";
import SearchIcon from "../../images/Search/Search.svg";
import CloseIcon from "../../images/Search/Close.svg";

export default function SearchBox({ handleSubmit, handleClose }) {
  const [search, setSearch] = useState("");
  const handle = (e) => {
    e.preventDefault();
    handleSubmit(search);
  };

  return (
    <div className="search-box-container">
      <div className="search-box">
        {/* <div className="search">
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Wanna search something?"
          />
          <img onClick={() => handleSubmit(search)} src={SearchIcon} alt="" />
        </div> */}
        <form className="search" onSubmit={handle}>
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Wanna search something?"
          />
          <button type="submit" className="search-button">
            <img src={SearchIcon} alt="" />
          </button>
        </form>

        <img
          onClick={() => {
            setSearch("");
            handleClose("");
          }}
          src={CloseIcon}
          alt=""
        />
      </div>
    </div>
  );
}
