import React, { useState } from "react";
import SocialRegiser from "../../components/SocialRegister/SocialRegiser";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import TextInput from "../../components/StyledComponent/TextInput";
import { useHistory } from "react-router-dom";
import { forgetPassword } from "../../actions/User";

export default function SignUpContainer({ setToast }) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loadingCTA, setLoadingCTA] = useState(false);

  const handleSubmit = (e) => {
    setLoadingCTA(true);

    e.preventDefault();
    const userData = {
      email,
    };

    forgetPassword(userData, setLoadingCTA, setToast, history);
  };

  return (
    <div className="forget-password-container">
      <h1>Forgot Password</h1>
      <form onSubmit={handleSubmit}>
        <TextInput
          title="Email"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <PrimaryCTA
          disabled={loadingCTA}
          type="submit"
          text="Next"
          loading={loadingCTA}
        />
      </form>
      <h3>OR </h3>

      <PrimaryCTA
        className="primary-cta-2"
        onClick={() => history.push("/")}
        text="Continue Without Login"
      />
    </div>
  );
}
