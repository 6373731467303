import React, { useState } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import AddressCard from "./AddressCard";
import { useHistory } from "react-router";
import { changeUserPassword } from "../../actions/User";
import { useSelector } from "react-redux";

export default function AddressContainer({ refetch, setToast }) {
  const history = useHistory();
  const { userAddress } = useSelector((state) => state.User);

  return (
    <div className="address-box">
      <div className="header">
        <h1>Billing Address</h1>
        <PrimaryCTA
          type="submit"
          onClick={() => history.push("/add-address")}
          text={"Add New Address"}
        />
      </div>

      <div className="card-container">
        {userAddress?.map((data) => (
          <AddressCard
            refetch={refetch}
            setToast={setToast}
            data={data}
            key={data?.id}
          />
        ))}
      </div>
    </div>
  );
}
