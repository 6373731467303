import Api from "../core/Api";
import { setWishListItems } from "../reducer/WishlistReducer";
import { setToast } from "../reducer/AppReducer";

export const addItemToWishList = (data) => {
  return (dispatch) => {
    Api.addToWishList(data)
      .then((res) => {
        if (res?.status) {
          dispatch(
            setToast({ open: true, isSuccess: true, msg: res?.data?.msg })
          );
          dispatch(setWishListItems(res?.data?.whishlist_products));
        }
      })
      .catch((e) => {});
  };
};

export const fetchAllWishList = () => {
  return (dispatch) => {
    Api.getAllWishLists()
      .then((res) => {
        if (res?.status) {
          dispatch(setWishListItems(res?.data));
        }
      })
      .catch((e) => {});
  };
};
