import React, { useState, useEffect } from "react";
import OfferCard from "../../components/Cards/OfferCard";

import { getHomeOffer } from "../../actions/Home";
export default function HomeOfferCard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getHomeOffer(setData, setLoading);
  }, []);

  return (
    <div className="home-offer-card">
      {data?.map((data, index) => (
        <OfferCard data={data} key={index} />
      ))}
    </div>
  );
}
