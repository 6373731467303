import React, { useState, useEffect } from "react";
import OfferCard from "../../components/Cards/OfferCard";
import { getBrandOffer } from "../../actions/BrandStore";
export default function BrandOfferCard({ id }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getBrandOffer(id, setData, setLoading);
  }, [id]);

  return (
    <div className="brand-store-offer-card">
      <h1>Brand Offers</h1>
      {data?.slice(0, 1).map((data) => (
        <OfferCard data={data} />
      ))}
    </div>
  );
}
