import axios from "axios";

const baseURL = "https://admin.kavishala.in";
// const baseURL = "http://52.66.120.132:8000/";
const kavishala = axios.create({
  baseURL: baseURL,
});

kavishala.defaults.headers.post["Content-Type"] = "application/json";

kavishala.interceptors.request.use((config) => {
  if (localStorage.getItem("store_token"))
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("store_token");
  else config.headers["Authorization"] = null;
  return config;
});

const Api = {
  login: (data) => {
    return kavishala.post(`/authentication/users/login/`, data);
  },
  signup: (data) => {
    return kavishala.post(`/authentication/users/register`, data);
  },
  forgetPassword: (data) => {
    return kavishala.post(`/user/changepassrequest/`, data);
  },
  resetPassword: (data) => {
    return kavishala.post(`/user/confirm-change-password/`, data);
  },
  socialLogin: (data) => {
    return kavishala.post(`/authentication/users/sociallogin/`, data);
  },
  twiterLogin: (data) => {
    return kavishala.post(`user/twitter_login/`, data);
  },
  //Home
  getFeatureProduct: () => {
    return kavishala.get(`/kavishala_store/feature_products/`);
  },
  getProductList: () => {
    return kavishala.get(`/kavishala_store/allproducts/`);
  },

  getHomePageBrands: () => {
    return kavishala.get(`/kavishala_store/brand_store_carousel/`);
  },
  getHomePageCover: () => {
    return kavishala.get(`/kavishala_store/homepage_carousel/`);
  },
  getHomePageOffer: () => {
    return kavishala.get(`/home_page/homepageoffer/`);
  },
  getFaq: () => {
    return kavishala.get(`/home_page/home_page_footer/`);
  },
  getPartners: () => {
    return kavishala.get(`/kavishala_store/shopbybrandstore/`);
  },
  getCategory: () => {
    return kavishala.get(`/kavishala_store/shopbycategory/`);
  },
  //Listing
  getListingProduct: (page, data) => {
    return kavishala.get(
      `kavishala_store/product_listing/?${data}&page=${page}`
    );
  },
  getProductFilterList: (id) => {
    return kavishala.get(`kavishala_store/product_listing/?category=${id}`);
  },
  getProductRangeList: (value2) => {
    return kavishala.get(
      `kavishala_store/product_listing/?price_range=${value2}`
    );
  },
  getListingFilter: () => {
    // return kavishala.get("/listing_page/filter_values/");
    return kavishala.get("/kavishala_store/shopbycategory/");
  },
  getListingFilterAttributes: () => {
    return kavishala.get("/listing_page/filter_attributes/");
  },
  //Brand Store
  getBrandProducts: (id) => {
    return kavishala.get(`/kavishala_store/brand_store/${id}`);
  },
  getBrandPageOffer: (id) => {
    return kavishala.get(`/brand_store/brand_offer/?partner_id=${id}`);
  },
  getBrandInformation: (id) => {
    return kavishala.get(`/brand_store/about_brand/?partner_id=${id}`);
  },
  getBrandPageBanner: (id) => {
    return kavishala.get(`/brand_store/brand_banner/?partner_id=${id}`);
  },

  //User
  getUserData: (id) => {
    return kavishala.get(`/${id}/`);
  },
  updateUserData: (slug, data) => {
    return kavishala.put(`/user/`, data);
  },
  changePassword: (data) => {
    return kavishala.post(`/user/changepassafterlogin/`, data);
  },
  addAddress: (data) => {
    return kavishala.post(`/kavishala_store/get_add_address/`, data);
  },
  editAddress: (id, data) => {
    return kavishala.put(`/kavishala_store/update_adress/${id}/`, data);
  },
  getAddress: () => {
    return kavishala.get(`/kavishala_store/get_add_address/`);
  },
  getAddressById: (id) => {
    return kavishala.get(`/kavishala_store/update_adress/${id}`);
  },
  deleteAddress: (id) => {
    return kavishala.delete(`/kavishala_store/update_adress/${id}`);
  },

  //Product
  getProductData: (id) => {
    return kavishala.get(`/kavishala_store/product/${id}/`);
  },
  getProductReview: (id) => {
    return kavishala.get(`/kavishala_store/add_get_rating/${id}`);
  },
  getProductReviewCount: (id) => {
    return kavishala.get(`/kavishala_store/avrage_rating/${id}`);
  },
  addProductReview: (data) => {
    return kavishala.post(`/kavishala_store/add_get_rating/`, data);
  },

  //WishLsit
  addToWishList: (data) => {
    return kavishala.post(`/kavishala_store/add_product_to_whishlist/`, data);
  },
  getAllWishLists: () => {
    return kavishala.get(`/kavishala_store/get_product_whishlist/`);
  },

  //Cart
  // createCart: () => {
  //   return kavishala.get(`kavishala_store/get_cart_details/`);
  // },
  getAllCartItems: (isLogin) => {
    // const cartId1 = localStorage.getItem("cartId");

    let authUrl = `kavishala_store/get_cart_details/`;

    let url = `kavishala_store/get_cart_details/`;
    return kavishala.get(isLogin ? authUrl : url);
  },
  addtoCart: (data) => {
    return kavishala.post(`kavishala_store/add_update_cart_details/`, data);
  },
  removeItem: (data) => {
    return kavishala.delete(
      `kavishala_store/delete_cart_details/${data?.product_id}`
    );
  },
  processToCheckout: (data) => {
    return kavishala.post(`cart_functions/get_and_add_to_cart/`, data);
  },
  buyNow: (data) => {
    return kavishala.post(`cart_functions/buynow/`, data);
  },
  getDeliveryCharge: () => {
    return kavishala.get(`/kavishala_store/shipping_details/`);
  },

  //checkOut
  checkoutData: (data) => {
    return kavishala.post(`payment/create_payment/`, data);
  },
  createPayment: (data) => {
    return kavishala.post(`/cart_functions/create_paymnet/`, data);
  },
  applyCoupon: (data) => {
    return kavishala.post(`/kavishala_store/use_coupon_code/`, data);
  },
  removeCoupon: (data) => {
    return kavishala.post(`/kavishala_store/use_coupon_code/`, data);
  },
  paymentFinalStep: (data) => {
    return kavishala.post(`/cart_functions/payments_status/`, data);
  },

  //search
  search: (data) => {
    return kavishala.get(
      `kavishala_store/search_product/?search=${data?.search_word}`
    );
  },

  //ORDER
  getOrder: (data) => {
    return kavishala.get(`kavishala_store/get_order_details/`, data);
  },
  // Sitemap
  getSitemap: () => {
    return kavishala.get(`sitemap.xml`);
  },
  publishDetails: (data) => {
    return kavishala.post(`/kavishala_store/publisher/`, data);
  },
  publisOnbordinghDetails: (data) => {
    return kavishala.post(`/kavishala_store/add_book_publisher/`, data);
  },
  productList: () => {
    return kavishala.get(`dashboard_api/partnerproductdashboard/`);
  },
  orderDetails: () => {
    return kavishala.get(`dashboard_api/partnercustomer/`);
  },
  stockDetails: () => {
    return kavishala.get(`dashboard_api/stockrecordproduct/`);
  },
};

export default Api;

export { baseURL };
