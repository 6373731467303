import React, { useEffect, useState } from "react";
import { getHomeBrands } from "../../actions/Home";
import CarouselCard from "../../components/Cards/CarouselCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function MobileHomeCarosel() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    getHomeBrands(setData, setLoading);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1.2,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.2,
      slidesToSlide: 1.2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
    },
  };
  return (
    <div className="home-carousel-contianer">
      <div className="header">
        <h1>Brand Store</h1>
        {/* <div className="right">
          {Array(data?.length)
            .fill()
            .map((data, index) => (
              <>
                <h1
                  onClick={() => setSelected(index)}
                  id={selected === index ? "selected" : ""}
                >{`0${index + 1}`}</h1>
                <div className="divider"></div>
              </>
            ))}
        </div> */}
      </div>

      <Carousel
        responsive={responsive}
        arrows={false}
        swipeable={true}
        draggable={true}
        autoPlay={true}
        showDots={false}
        ssr={true}
        renderDotsOutside={true}
        infinite
      >
        {data?.map((data) => (
          <CarouselCard data={data} key={data?.id} />
        ))}
      </Carousel>
    </div>
  );
}
