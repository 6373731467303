import React from "react";
import AppBar from "../../components/TopBar/AppBar";
import Dashboard from "../../components/Dashboard/DashboardBtn";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import MobileFooter from "../../components/Footer/MobileFooter";
import Listing from "../../components/Dashboard/Listing";
import SEO from "../../SEO";
const Mydashboard = () => {
  return (
    <div>
      <SEO
        title={`My Dashboard | Kavishala Store`}
        og_url={"/vendor-dashboard"}
        og_type="e-commerce"
        og_title={`My Dashboard | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      {/* <AppBar /> */}
      {/* <Dashboard /> */}
      <Listing />
      {/* <DesktopFooter />
      <MobileFooter /> */}
    </div>
  );
};

export default Mydashboard;
