import React, { useState } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";

import ChangePasswordInput from "./ChangePasswordInput";
import { changeUserPassword } from "../../actions/User";

export default function ChangePasswordContainer({ setToast }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnfNewPassword, setCnfNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const resetData = () => {
    setCnfNewPassword("");
    setCurrentPassword("");
    setNewPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword.length < 8 || currentPassword.length < 8) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Password must be of 8 Characters",
      });
      return;
    }
    if (newPassword !== cnfNewPassword) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "New Password does not match with Confirm Password",
      });
      return;
    }

    const tdata = {
      current_passowrd: currentPassword,
      new_password: cnfNewPassword,
    };
    setLoading(true);
    changeUserPassword(tdata, setLoading, setToast, resetData);
  };
  return (
    <div className="change-password-box">
      <h1 className="change-password-txt">Change Password</h1>
      <div className="card-container">
        <form onSubmit={handleSubmit}>
          <ChangePasswordInput
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e)}
            type="Current Password"
          />
          <ChangePasswordInput
            value={newPassword}
            onChange={(e) => setNewPassword(e)}
            type="New Password"
          />
          <ChangePasswordInput
            value={cnfNewPassword}
            onChange={(e) => setCnfNewPassword(e)}
            type="Confirm New Password"
          />

          <PrimaryCTA
            loading={loading}
            disabled={loading}
            type="submit"
            text={"Save"}
          />
        </form>
      </div>
    </div>
  );
}
