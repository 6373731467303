import React, { useState, useEffect } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import PaymentFailedContainer from "./PaymentFailedContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { useHistory, useParams } from "react-router-dom";
import MobileFooter from "../../components/Footer/MobileFooter";
export default function PaymentFailed() {
  const [appDrawer, setAppDrawer] = useState(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params?.id) {
    } else {
      history.push("/");
    }
  }, []);
  return (
    <div className="payment">
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="payment-container">
        <PaymentFailedContainer />
        <DesktopFooter />
        <MobileFooter />
      </div>
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
