import React, { useState } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import MyOrdersContiner from "./MyOrdersContiner";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function MyOrder() {
  const [appDrawer, setAppDrawer] = useState(false);
  return (
    <>
      <SEO
        title={`My Order | Kavishala Store`}
        og_url={"/myorder"}
        og_type="e-commerce"
        og_title={`My Order  | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="myorder">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="myorder-container">
          <MyOrdersContiner />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
      </div>
    </>
  );
}
