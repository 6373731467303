import Api from "../core/Api";

export const getBrandStoreCover = (id, setData, setLoading) => {
  Api.getBrandPageBanner(id)
    .then((res) => {
      if (res?.data?.msg === "success") {
        // let tdata = {
        //   image: res?.data?.data[0]?.image,
        //   title: res?.data?.data[0]?.name,
        //   desc: res?.data?.data[0]?.about_brand,
        // };
        setData(res?.data?.data);
        setLoading(false);
      }
    })
    .catch((e) => {});
};

export const getBrandProduct = (id, setData, setLoading) => {
  Api.getBrandProducts(id)
    .then((res) => {
      if (res?.status === 200) {
        setData(res?.data);
        setLoading(false);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const getBrandOffer = (id, setData, setLoading) => {
  Api.getBrandPageOffer(id)
    .then((res) => {
      if (res?.data?.status) {
        setData(res?.data?.data);
        setLoading(false);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const getBrandinfo = (id, setData, setLoading) => {
  Api.getBrandInformation(id)
    .then((res) => {
      if (res?.data?.status) {
        setData(res?.data?.data);
        setLoading(false);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};
