import React from "react";
import WishListCard from "../../components/Cards/WishListCard";
import WishListHeader from "./WishListHeader";
import { useSelector } from "react-redux";

export default function WishListContiner() {
  const { wishlistItems } = useSelector((state) => state.WishList);

  return (
    <div className="wishlist-box">
      <h1 className="your-wishlist">Wishlist</h1>
      <WishListHeader />
      <div>
        {wishlistItems?.map((data) => (
          <WishListCard data={data} />
        ))}
      </div>
    </div>
  );
}
