import React from "react";
import lockIcon from "./../../images/ChangePassword/lock.svg";

export default function ChangePasswordInput({ value, onChange, type }) {
  return (
    <div className="change-password-input">
      <h1>{type}</h1>
      <div className="container">
        <img src={lockIcon} alt="" />
        <input
          type={"text"}
          value={value}
          onChange={(e) => onChange(e.target?.value)}
          required
        />
      </div>
    </div>
  );
}
