import React, { useState, useEffect } from "react";
import Check from "../StyledComponent/Check";
import PrimaryCTA from "../StyledComponent/PrimaryCTA";
import cross from "./../../images/Cards/cross.svg";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart } from "../../actions/Cart";
import { setItemModal } from "../../reducer/AppReducer";
import { useHistory } from "react-router";
import { removeItemFromCart } from "../../actions/Cart";
import { addItemToWishList } from "../../actions/WishList";
export default function WishListCard({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inCart, setInCart] = useState(false);
  const { itemModal } = useSelector((state) => state.App);
  const { wishlistItems } = useSelector((state) => state.WishList);
  const item = data;
  const { cartId } = useSelector((state) => state.Cart);
  const { cartItems } = useSelector((state) => state?.Cart);

  useEffect(() => {
    let index = cartItems?.findIndex((e) => e.product?.id === item?.id);

    if (index !== -1) {
      setInCart(true);
    } else {
      setInCart(false);
    }
  }, [cartItems, item?.id]);

  // const handleClose = () => {
  //   dispatch(
  //     setItemModal({
  //       open: false,
  //       item: {},
  //     })
  //   );
  // };

  const handleAddToCart = () => {
    let data = {
      // basket_id: cartId,
      product_id: item?.id,
      count: qty,
    };
    setLoading(true);
    dispatch(addItemToCart(data, setLoading));
  };
  const handleWishList = () => {
    dispatch(addItemToWishList({ product_id: item?.id }));
  };
  return (
    <>
      <div className="wishlist-card">
        <div className="left">
          <img className="item-img" src={data?.image} alt="" />
          <div className="info">
            <h1>{data?.title?.title}</h1>
            <p>Author : Gopichand Narang </p>
            <p>ASIN : 0010201020231525</p>
          </div>
          {!inCart ? (
            <div className="btn-group">
              <PrimaryCTA
                loading={loading}
                text="Add To Cart"
                onClick={handleAddToCart}
              />
            </div>
          ) : (
            <div className="btn-group">
              <PrimaryCTA
                loading={loading}
                text="Added in Cart"
                onClick={handleAddToCart}
              />
            </div>
          )}
        </div>

        <div className="right">
          <div className="cross">
            <div onClick={handleWishList}>
              <img src={cross} alt="" />
            </div>
          </div>
          <div className="price">
            <p>₹ {data?.price} </p>
          </div>
        </div>
      </div>
      {/* Mobile responsive */}
      <div className="mob-wishlist-card">
        <div className="left">
          <img className="item-img" src={data?.image} alt="" />
          <div className="info">
            <h1>{data?.title?.title}</h1>
            <p>Author : Gopichand Narang </p>
            <p>ASIN : 0010201020231525</p>
          </div>
        </div>

        <div className="right">
          {!inCart ? (
            <div className="btn-group">
              <PrimaryCTA
                loading={loading}
                text="Add To Cart"
                onClick={handleAddToCart}
              />
            </div>
          ) : (
            <div className="btn-group">
              <PrimaryCTA
                loading={loading}
                text="Added in Cart"
                onClick={handleAddToCart}
              />
            </div>
          )}
          <div className="cross">
            <div onClick={handleWishList}>
              <img src={cross} alt="" />
            </div>
          </div>
          <div className="price">
            <p>₹ {data?.price} </p>
          </div>
        </div>
      </div>
    </>
  );
}
