import React, { useState, useEffect } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import CheckoutContainer from "./CheckoutContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../../actions/User";
import { useHistory } from "react-router-dom";
import { resetToast } from "../../reducer/AppReducer";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function Checkout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { toast } = useSelector((state) => state?.App);
  const { proccedToCheckOut } = useSelector((state) => state.Cart);
  // const {}=useSelector((state=>state.))
  const [appDrawer, setAppDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!proccedToCheckOut) history.push("/");
  // }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserAddress(setLoading));
  }, []);

  return (
    <>
      <SEO
        title={`Checkout | Kavishala Store`}
        og_url={"/checkout"}
        og_type="e-commerce"
        og_title={`Checkout| Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="checkout">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="checkout-container">
          <CheckoutContainer
          //  data={proccedToCheckOut}
          />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
        <Toast data={toast} handleClose={() => dispatch(resetToast())} />
      </div>
    </>
  );
}
