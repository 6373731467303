import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  wishlistItems: [],
};
const wishListSlice = createSlice({
  name: "wishlist",
  initialState: defaultState,
  reducers: {
    setWishListItems: (state, action) => {
      state.wishlistItems = action.payload;
    },
    resetWishListReducer: (state, action) => (state = defaultState),
  },
});

export const { setWishListItems, resetWishListReducer } = wishListSlice.actions;
export default wishListSlice.reducer;
