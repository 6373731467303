import React from "react";

export default function InfoInput({ value, onChange, img, type, edit }) {
  return (
    <div className="info-input">
      <h1>{type}</h1>
      <div className="container">
        <img src={img} alt="" />
        <input
          type={type === "Phone" ? "number" : "text"}
          value={value}
          onChange={(e) => onChange(e.target?.value)}
          style={{ boxShadow: !edit ? "none" : null }}
          readOnly={!edit}
          required
        />
      </div>
    </div>
  );
}
