import React, { useState } from "react";
import { applyPromo, removePromo } from "../../actions/Checkout";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { useDispatch, useSelector } from "react-redux";
import {
  setCouponDiscount,
  setCoupon,
  setisCouponApplied,
  setCoupanId,
} from "../../reducer/CartReducer";

export default function Coupon({ cartId }) {
  const dispatch = useDispatch();
  const { coupon, isCouponApplied } = useSelector((state) => state.Cart);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let temp = {
      code: coupon,
    };
    dispatch(applyPromo(temp, setLoading));
  };

  const resetState = () => {
    dispatch(setCoupon(""));
    dispatch(setisCouponApplied(false));
    dispatch(setCouponDiscount(0));
    dispatch(setCoupanId(0));
  };
  const removeToken = () => {
    // let temp = {
    //   code: coupon,
    // };
    // dispatch(removePromo(temp, resetState, setLoading));
    resetState();
    setCoupon("");
  };
  return (
    <form onSubmit={handleSubmit} className="checkout-coupon">
      <input
        value={coupon}
        required
        readOnly={isCouponApplied}
        onChange={(e) => dispatch(setCoupon(e.target.value))}
        type="text"
      />
      {isCouponApplied ? (
        <PrimaryCTA onClick={removeToken} text="Remove " />
      ) : (
        <PrimaryCTA type="submit" text="Apply Promocode" />
      )}
    </form>
  );
}
