import React, { useState, useEffect } from "react";
import HomeItemCard from "../../components/Cards/HomeItemCard";
import SkeletonCard from "../../components/Cards/SkeletonCard";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import AllSelected from "./../../images/Home/All_Selected.svg";
import All from "./../../images/Home/All.svg";
import Book from "./../../images/Home/Book.svg";
import BookSelected from "./../../images/Home/Book_Selected.svg";
import ClothIcon from "./../../images/Home/Cloth.svg";
import ClothSelctedIcon from "./../../images/Home/Cloth_Selected.svg";
import { getProductsListData } from "../../actions/Home";
import { useHistory } from "react-router-dom";
import NoImage from "./../../images/Cards/no-image.svg";

export default function HomeProduct() {
  const history = useHistory();

  const [books, setBooks] = useState([]);
  const [all, setAll] = useState([]);
  const [cloth, setCloth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [renderCards, setRenderCards] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setLoading(true);
    getProductsListData(setBooks, setCloth, setAll, setRenderCards, setLoading);
  }, []);

  return (
    <div className="home-product">
      <h1>Products</h1>
      <div className="home-product-menu-bar">
        <img
          onClick={() => {
            setSelected(0);
            setRenderCards(all);
          }}
          src={selected === 0 ? AllSelected : All}
          alt=""
        />
        <img
          onClick={() => {
            setSelected(1);
            setRenderCards(books);
          }}
          src={selected === 1 ? BookSelected : Book}
          alt=""
        />
        <img
          onClick={() => {
            setSelected(2);
            setRenderCards(cloth);
          }}
          src={selected === 2 ? ClothSelctedIcon : ClothIcon}
          alt=""
        />
      </div>
      <div className="card-container">
        {loading
          ? Array(8)
              ?.fill()
              ?.map((e, i) => <SkeletonCard key={i} />)
          : renderCards
              ?.slice(0, 9)
              ?.map((data) => (
                <HomeItemCard
                  key={data?.id}
                  id={data?.id}
                  image={data?.image || NoImage}
                  price={data?.price}
                  discountPrice={data?.discount_price}
                  name={data?.name}
                  rating={data?.rating}
                  slug={data?.slug}
                />
              ))}
      </div>
      <div className="cta-container">
        <PrimaryCTA
          onClick={() => history.push("/catalogue")}
          text="SEE PRODUCT LISTING"
        />
      </div>
    </div>
  );
}
