import React, { useEffect, useState } from "react";
import SkeletonCard from "../../components/Cards/SkeletonCard";
import { getFeatureProducts } from "../../actions/Home";
import HomeItemCard from "../../components/Cards/HomeItemCard";
import NoImage from "./../../images/Cards/no-image.svg";

export default function FeatureProduct() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFeatureProducts(setData, setLoading);
  }, []);

  return (
    <div className="home-feature-product">
      <h1>Featured Products</h1>
      <div className="feature-product-container">
        {loading
          ? Array(3)
              .fill()
              .map((e, i) => <SkeletonCard key={i} />)
          : data?.map((data) => {
              return (
                <HomeItemCard
                  key={data?.product?.id}
                  id={data?.product?.id}
                  image={data?.product?.image || NoImage}
                  price={data?.product?.price}
                  discountPrice={data?.product?.discount_price}
                  name={data?.product?.name}
                  rating={data?.product?.rating}
                  slug={data?.product?.slug}
                />
              );
            })}
      </div>
    </div>
  );
}
