import React, { useEffect, useState } from "react";
import cartCircle from "./../../images/Cards/cartCircle.svg";
import cart from "./../../images/Cards/cart.svg";
import cartSelected from "./../../images/Cards/cart-selected.svg";
import heartCircle from "./../../images/Cards/heartCircle.svg";
import heart from "./../../images/Cards/heart.svg";
import heartfilled from "./../../images/Cards/heart-filled.svg";
import Starfilled from "./../../images/Cards/star-filled.svg";
import Star from "./../../images/Cards/star.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../actions/Cart";
import { addItemToWishList } from "../../actions/WishList";
import { setItemModal } from "../../reducer/AppReducer";

const CartInfo = ({ item }) => {
  const history = useHistory();
  localStorage.setItem("reviewId", item?.id);
  return (
    <div
      className="home-card-info"
      onClick={() =>
        history.push(`/catalogue/${item?.slug}`, {
          id: item?.id,
        })
      }
    >
      <h1 className="name">{item?.name}</h1>
      <div className="rating">
        {Array(item?.rating)
          .fill()
          .map((e, _i) => (
            <img src={Starfilled} key={_i} alt="" />
          ))}
        {Array(5 - item?.rating)
          .fill()
          .map((e, _i) => (
            <img src={Star} key={_i} alt="" />
          ))}
      </div>
      <div className="price">
        <p>₹ {item?.discountPrice}</p>

        <p>
          <span>₹ {item?.price}</span>
        </p>
      </div>
    </div>
  );
};

const ItemBar = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inWhishlist, setInWhishlist] = useState(false);
  const [inCart, setInCart] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const { wishlistItems } = useSelector((state) => state.WishList);
  const { cartItems } = useSelector((state) => state?.Cart);

  useEffect(() => {
    let findIndex = wishlistItems?.findIndex((e) => e?.id === item?.id);
    if (findIndex !== -1) {
      setInWhishlist(true);
    } else {
      setInWhishlist(false);
    }
  }, [wishlistItems]);

  useEffect(() => {
    let findIndex = cartItems?.findIndex((e) => e?.product?.id === item?.id);
    if (findIndex !== -1) {
      setInCart(true);
    } else {
      setInCart(false);
    }
  }, [cartItems]);

  const handleWishList = () => {
    isLogin
      ? dispatch(addItemToWishList({ product_id: item?.id }))
      : history.push("/login");
  };

  const handleCartClick = () => {
    dispatch(
      setItemModal({
        open: true,
        item: item,
      })
    );
  };

  return (
    <div className="home-item-desktop-bar">
      <div onClick={handleWishList} className="circle">
        <img src={inWhishlist ? heartfilled : heart} alt="" />
      </div>
      <div onClick={handleCartClick} className="circle">
        <img src={inCart ? cartSelected : cart} alt="" />
      </div>
    </div>
  );
};

const HoveredCard = ({ item, setHover }) => {
  return (
    <div
      style={{ backgroundImage: `url(${item?.image})` }}
      className="hovered-card"
      onMouseLeave={() => setHover(false)}
    >
      <div className="container">
        <ItemBar item={item} />
      </div>
    </div>
  );
};
const NormalCard = ({ item, setHover }) => {
  return (
    <div className="normal-card" onMouseOver={() => setHover(true)}>
      <img className="cover-img" src={item?.image} alt="" />
    </div>
  );
};

export default function HomeItemCard(item) {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  return (
    <div
      className="home-item-card"
      onClick={() =>
        history.push(`/catalogue/${item?.slug}`, {
          id: item?.id,
        })
      }
    >
      {hover ? (
        <HoveredCard item={item} setHover={(e) => setHover(e)} />
      ) : (
        <NormalCard item={item} setHover={(e) => setHover(e)} />
      )}
      <CartInfo item={item} />
    </div>
  );
}
