import React, { useEffect, useState } from "react";
import fbIcon from "./../../images/Social/logos_facebook.svg";
import googleIcon from "./../../images/Social/logos_google.svg";
import twitterIcon from "./../../images/Social/logos_twitter.svg";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import TwitterLogin from "react-twitter-login";
import { socialsLogin } from "../../actions/User";
import { gapi } from "gapi-script";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginFooter from "../../screens/Login/LoginFooter";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
export default function SocialRegiser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingCTA, setLoadingCTA] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });
  const { cartId } = useSelector((state) => state.Cart);

  const clientId =
    "401857075098-j22vtusmfj47bqeujk18u5edhgvl57ml.apps.googleusercontent.com";

  const responseGoogle = (response) => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
    setLoadingCTA(true);
    const data = {
      user: {
        email: response.profileObj.email,
        username: response.profileObj.name,
        provider: "google",
        token: response.accessToken,
      },
    };
    dispatch(socialsLogin(data, setLoadingCTA, setToast, history, cartId));
  };

  const componentClicked = () => {};
  const responseFacebook = (response) => {};
  const authHandler = (err, res) => {};

  return (
    <>
      {/* <PrimaryCTA
        onClick={() => history.push("/sign-up")}
        text="Create your Kavishala Account"
      /> */}
      <div className="social-register">
        <div className="social">
          {/* <FacebookLogin
            appId="1206715649505081"
            fields="name,email,picture"
            textButton=""
            callback={responseFacebook}
            icon=<img src={fbIcon} alt="" />
            onClick={componentClicked}
            buttonStyle={undefined}
            cssClass="fbIcon"
          />

          <div className="divider"></div> */}
          <GoogleLogin
            clientId={clientId}
            buttonText={"Login with Google"}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            className="googleIcon"
            icon={false}
            style={{ padding: "0" }}
          >
            <div className="social_icon">
              <img src={googleIcon} alt="" />
              <h1 className="login">Login with Google </h1>
            </div>
          </GoogleLogin>

          {/* <div className="divider"></div>
          <TwitterLogin
            authCallback={authHandler}
            consumerKey="j5LR8vP8DB77sc0eMpLpmgMcH"
            consumerSecret={
              "mw9TLBPRkFnXu5TsOeQzBTZN4n3sPPUb0UPBeMmCXXo0zWb4z6"
            }
            // callbackUrl="http://localhost:3000/"
            buttonTheme={"light"}
            text={undefined}
            className="twitterIcon"
            scope="openId email name"
          >
            <img src={twitterIcon} alt="" />
          </TwitterLogin> */}
        </div>

        <div className="flex-center">
          <div className="footer-line"></div>
        </div>
      </div>
    </>
  );
}
