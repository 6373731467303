import Api from "../core/Api";
import { setToast } from "../reducer/AppReducer";
import {
  setCouponDiscount,
  setisCouponApplied,
  setCoupanId,
} from "../reducer/CartReducer";

export const applyPromo = (data, setLoading) => {
  return (dispatch) => {
    Api.applyCoupon(data)
      .then((response) => {
        if (response.status === 200) {
          if (response?.status) {
            console.log("retshgvsghj", response?.data?.code_id);
            dispatch(setCouponDiscount(response?.data?.discount_amount));
            dispatch(setCoupanId(response?.data?.code_id));
            dispatch(setisCouponApplied(true));
            // dispatch(setCouponValue(response));
            dispatch(
              setToast({
                open: true,
                isSuccess: true,
                msg: response?.data?.msg,
              })
            );
            setLoading(false);
          }
          if (response?.data?.status === false) {
            dispatch(
              setToast({
                open: true,
                isSuccess: false,
                msg: response?.data?.msg,
              })
            );
            setLoading(false);
          }
        }
      })
      .catch((e) => console.log("Error", e));
  };
};
export const removePromo = (data, resetState, setLoading) => {
  return (dispatch) => {
    Api.removeCoupon(data)
      .then((response) => {
        if (response.status === 200) {
          if (response?.status) {
            resetState();
            dispatch(
              setToast({
                open: true,
                isSuccess: true,
                msg: response?.data?.msg,
              })
            );
            setLoading(false);
          }
          if (response?.data?.status === false) {
            dispatch(
              setToast({
                open: true,
                isSuccess: false,
                msg: response?.data?.msg,
              })
            );
            setLoading(false);
          }
        }
      })
      .catch((e) => console.log("Error", e));
  };
};
var basket_id;
export const checkoutItems = (data, id, paymentAmt, setLoading) => {
  Api.checkoutData(data)
    .then((res) => {
      if (res?.status === 200) {
        let paymentData = {
          currency: "INR",
          amount: parseInt(res?.data?.amount * 100),
          order_id: parseInt(res?.data?.order_id),
        };
        startPayment(res?.data?.order_id);
        // proccedToPayment(paymentData);
      }
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const proccedToPayment = (data) => {
  Api.createPayment(data)
    .then((response) => {
      if (response.status === 200) {
        startPayment(response?.data.id);
      }
    })
    .catch((e) => console.log("Error", e));
};
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error("Failed to load script"));
    document.body.appendChild(script);
  });
};
function startPayment(orderId) {
  loadScript("https://checkout.razorpay.com/v1/checkout.js").then(() => {
    var options = {
      key: "rzp_live_GjHtQ6qexhz21c",
      // key: "rzp_test_HcJjWYAt7O5rgl",
      name: "Kavishala Store",
      description: "Powered By Kavishala",
      image: "https://www.feelfreetocode.com/logo.png?v=2",
      order_id: orderId,
      handler: handleBookingPaymentResponse,
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      let paymetStatus = {
        status: "FAILED",
        description: response.error.description,
        code: response.error.code,
        source: response.error.source,
        reason: response.error.reason,
        order_id: response.error.metadata.order_id,
        payment_id: response.error.metadata.payment_id,
      };
      handlePaymentProcessing(paymetStatus);
    });

    rzp1.open();
  });
}

const handleBookingPaymentResponse = (res) => {
  let paymetStatus = {
    status: "SUCCESS",
    razorpay_order_id: res?.razorpay_order_id,
    razorpay_payment_id: res?.razorpay_payment_id,
    razorpay_signature: res?.razorpay_signature,
    user_basket: basket_id,
  };

  handlePaymentProcessing(paymetStatus);
};

const handlePaymentProcessing = (data) => {
  Api.paymentFinalStep(data)
    .then((res) => {
      if (res.status === 200) {
        if (data?.status === "SUCCESS") {
          window.location.href = `/payment-success/${data?.razorpay_order_id}`;
        } else {
          window.location.href = `/payment-failed/${data?.order_id}`;
        }
      }
    })
    .catch((e) => console.log("error"));
};
