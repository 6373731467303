import React from "react";
import ListingCardContainers from "./ListingCardContainers";

export default function RightPanel() {
  return (
    <div className="listing-right-panel">
      <ListingCardContainers />
    </div>
  );
}
