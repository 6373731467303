import React from "react";

export default function CartHeader() {
  return (
    <div className="cart-header">
      <div className="div-1">
        <h1>Select All</h1>
      </div>
      <div className="div-2">
        <h1>Description</h1>
      </div>
      <div className="div-3">
        <h1>Quantity</h1>
      </div>
      <div className="div-4">
        <h1>Remove</h1>
      </div>
      <div className="div-5">
        <h1>Price</h1>
      </div>
    </div>
  );
}
