import React, { useState, useEffect } from "react";
import DashboardBtn from "./DashboardBtn";

const DashBoardtabs = ({ selected, setSelected }) => {
  const [content, setContent] = useState([]);
  const handleChange = (key) => {
    setSelected(key);
    switch (key) {
      case "REDEEM":
        setContent(content);
        break;
      case "EARN":
        setContent(content);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelected("REDEEM");
    setContent(content);
  }, []);
  return (
    <>
      <div>
        <div>
          <DashboardBtn selected={selected} setSelected={handleChange} />
        </div>
      </div>
    </>
  );
};

export default DashBoardtabs;
