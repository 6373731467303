import React from "react";
import { useHistory } from "react-router-dom";

export default function AccountCard({ title, img, desc, path }) {
  const history = useHistory();
  return (
    <div className="account-card" onClick={() => history.push(path)}>
      <img src={img} alt="" />
      <h1>{title}</h1>
      <p>{desc}</p>
    </div>
  );
}
