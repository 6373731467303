import React, { useState, useEffect } from "react";
import AppBar from "../../components/TopBar/AppBar";
import Layout1 from "../../components/Layout/Layout1";
import BrandProduct from "./BrandProduct";
import AboutBrand from "./AboutBrand";
import BrandOfferCard from "./BrandOfferCard";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { getBrandStoreCover } from "../../actions/BrandStore";
import { useParams, useHistory } from "react-router-dom";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import ItemModal from "../../components/Modals/ItemModal";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function BrandStore() {
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state?.App);
  const params = useParams();
  const history = useHistory();
  const [cover, setCover] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appDrawer, setAppDrawer] = useState(false);
  const path = window.location.pathname;
  localStorage.setItem("path", path);

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getBrandStoreCover(params?.id, setCover, setLoading);
    } else {
      history.push("/");
    }
  }, [history, params]);
  console.log("kjhbjn", params?.id?.toUpperCase());
  return (
    <div className="brand-store">
      <SEO
        title={`Brand Store | ${params?.id?.toUpperCase()} | Kavishala Store `}
        og_url={path}
        og_type="e-commerce"
        og_title={`Brand Store | ${params?.id?.toUpperCase()} | Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="brand-store-container">
        {!loading && <Layout1 data={cover} />}
        <BrandProduct id={params?.id} />
        <AboutBrand id={params?.id} />
        <BrandOfferCard id={params?.id} />
        <DesktopFooter />
        <MobileFooter />
      </div>
      <ItemModal />
      <Toast data={toast} handleClose={() => dispatch(resetToast())} />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
