import React, { useState } from "react";
import EyeOpen from "./../../images/Input/eye_open.svg";
import EyeClose from "./../../images/Input/eye_close.svg";

export default function PasswordInput({ title, ...rest }) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="styled-password-input">
      <p>{title} </p>
      <div className="password">
        <input type={visible ? "text" : "password"} {...rest} />
        <img
          src={visible ? EyeClose : EyeOpen}
          onClick={() => setVisible(!visible)}
          alt=""
        />
      </div>
    </div>
  );
}
