import React, { useEffect, useState } from "react";
import AppBar from "../../components/TopBar/AppBar";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import { getProductInfo } from "../../actions/ProductDetails";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import ProductInfo from "./ProductInfo";
import { useDispatch, useSelector } from "react-redux";
import Reviews from "./Reviews";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function ProductDetails() {
  const params = useParams();
  const { slug } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state?.App);

  const { state } = useLocation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [appDrawer, setAppDrawer] = useState(false);

  useEffect(() => {
    if (slug) {
      setLoading(true);
      getProductInfo(slug, setData, setLoading);
    } else {
      history.push("/");
    }
  }, [slug, history]);

  const path = window.location.pathname;
  localStorage.setItem("path", path);
  const id = localStorage.getItem("reviewId");
  console.log("hgfgh", data);
  return (
    <div className="product">
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <SEO
        title={`Catalogue | ${data?.name} | Kavishala Store `}
        og_url={`/catalogue/${data?.slug}`}
        og_type="e-commerce"
        og_title={`Catalogue | ${data?.name} | Kavishala Store `}
        og_description={data?.discription}
        og_image={data?.image}
      />
      <div className="product-container">
        {!loading && (
          <>
            <ProductCard data={data} />
            <ProductInfo data={data} />
            <Reviews id={data?.id} />
          </>
        )}
        <Toast data={toast} handleClose={() => dispatch(resetToast())} />
        <DesktopFooter />
        <MobileFooter />
      </div>
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
