import React from "react";
import AccountContainer from "../Account/AccountContainer";
export default function PaymentSuccessContainer() {
  return (
    // <div className="payment-box">
    //   <h1 className="payment-txt">Payment </h1>
    //   <div className="payment-success-container">
    //     <h1>Payment Success</h1>
    <AccountContainer />
    //   </div>
    // </div>
  );
}
