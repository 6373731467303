import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import { getHomeCatogery, getHomePartners } from "../../actions/Home";
import { useHistory, useLocation } from "react-router-dom";

export default function AppBarDrawer({ open, handleClose }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isLogin, userInfo } = useSelector((state) => state.User);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (open) {
      getHomePartners(setData, setLoading);
      getHomeCatogery(setCategory, setLoading);
    }
  }, [open]);

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <div className="appbar-drawer">
        <div className="top-bar">
          {isLogin ? <h1>Hi {userInfo?.username}!! </h1> : <h1>Hi User !! </h1>}
        </div>
        <div className="content">
          <h1>Shop by Brand Stores</h1>
          {data?.map((data, key) => (
            <p
              onClick={() => history.push(`/brand-store/${data?.code}`)}
              key={key}
            >
              {data?.name}
            </p>
          ))}

          <div className="divider" />

          {pathname !== "/catalogue" && (
            <>
              <h1>Shop by Categories</h1>
              {category?.map((data, key) => (
                <p
                  onClick={() => history.push(`/catalogue`, { id: data?.id })}
                  key={key}
                >
                  {data?.name}
                </p>
              ))}
            </>
          )}
          <h1
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/publisher-onbording`)}
          >
            Become Publisher
          </h1>
        </div>
      </div>
    </Drawer>
  );
}
