import React from "react";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
export default function PrimaryCTA({ text, loading, ...rest }) {
  return (
    <div className="cta-container">
      <Button variant="contained" {...rest}>
        {loading ? <CircularProgress size={28} /> : text}
      </Button>
    </div>
  );
}
