import React, { useState, useEffect } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import { changeUserPassword } from "../../actions/User";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CheckOutAddressCard from "./CheckOutAddressCard";
import { checkoutItems } from "./../../actions/Checkout";
import CheckoutItems from "./CheckoutItems";
import { baseURL } from "../../core/Api";
import Coupon from "./Coupon";
import BackArrow from "../../images/Modal/BackArrow.png";
import { getDeliveyCharges } from "../../actions/Cart";

export default function ShippingAddressContainer({ data }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [shippingChrg, setshippingChrg] = useState();
  const { userAddress, selectedIndex, userInfo } = useSelector(
    (state) => state.User
  );
  const { proccedToCheckOut } = useSelector((state) => state?.Cart);
  const { couponDiscount } = useSelector((state) => state.Cart);
  const { coupanId } = useSelector((state) => state.Cart);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    calculateTotal();
    getDeliveyCharges(setshippingChrg);
  }, [data]);

  const calculateTotal = () => {
    let totalPrice = data?.check_out_cart_data?.reduce(
      (a, b) => (a += parseFloat(b.total)),
      0
    );
    setTotal(totalPrice?.toFixed(2));
  };

  const handleContinue = () => {
    // let tdata = {
    //   basket: `${baseURL}api/baskets/${data?.check_out_cart_id}/`,
    //   guest_email: userInfo?.email,
    //   total: (total - couponDiscount).toFixed(2) + "",
    //   shipping_charge: {
    //     currency: "INR",
    //     excl_tax: "0.0",
    //     tax: "0.0",
    //   },
    //   shipping_method_code: "no-shipping-required",
    //   shipping_address: userAddress[selectedIndex],
    //   billing_address: userAddress[selectedIndex],
    // };

    const tdata = {
      products: proccedToCheckOut,
      user_address: userAddress[selectedIndex]?.id,
      payment_to: "store",
      get_id: 90,
      shipping_id: shippingChrg?.id,
    };
    if (coupanId != 0) tdata.coupon_id = coupanId;

    let paymentAmt = total - couponDiscount + shippingChrg;
    setLoading(true);
    checkoutItems(tdata, data?.check_out_cart_id, paymentAmt, setLoading);
  };

  const addTotal = proccedToCheckOut.map(
    (value) => value?.amount * value?.count
  );
  const totals = addTotal.reduce((total, amount) => total + amount, 0);

  return (
    <div className="checkout-box">
      <div className="header">
        <img
          className="backArrow"
          src={BackArrow}
          alt=""
          onClick={() => history.push("/shipping-address")}
        />
        <h1>Checkout</h1>
      </div>

      <div className="card-container">
        <div className="box">
          <div className="left-box">
            <CheckOutAddressCard data={userAddress[selectedIndex]} />
            <Coupon cartId={data?.check_out_cart_id} />
          </div>

          <CheckoutItems
            data={data}
            total={totals}
            couponDiscount={couponDiscount}
            shippingChrg={shippingChrg}
          />
        </div>
        <div className="checkout-cta">
          <PrimaryCTA
            loading={loading}
            onClick={handleContinue}
            text={`Proceed to Pay ${(
              totals -
              couponDiscount +
              shippingChrg?.amount
            ).toFixed(2)}`}
          />
        </div>
      </div>
    </div>
  );
}
