import React, { useEffect, useState } from "react";
import AppBar from "../../components/TopBar/AppBar";
import SearchBox from "./SearchBox";
import SearchItemContainer from "./SearchItemContainer";
import { searchItem } from "../../actions/Search";
import ItemModal from "../../components/Modals/ItemModal";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import SEO from "../../SEO";
export default function Search() {
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state?.App);
  const { page, listingData } = useSelector((state) => state.Listing);
  const [appDrawer, setAppDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const handleSearch = (e) => {
    setIsEmpty(false);
    let data = { search_word: e };
    setLoading(true);

    searchItem(data, setResult, result, setIsEmpty, setLoading);
  };
  const path = window.location.pathname;
  localStorage.setItem("path", path);
  return (
    <div className="search">
      <SEO
        title={`Search | Kavishala Store `}
        og_url={"/search"}
        og_type="e-commerce"
        og_title={`Search | Kavishala Store `}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="search-container">
        <SearchBox
          handleSubmit={(e) => handleSearch(e)}
          handleClose={() => {
            setIsEmpty(false);
            setLoading(false);
            setResult([]);
          }}
        />
        {isEmpty ? (
          <h1 className="resultNotFound">No Result Found</h1>
        ) : (
          <SearchItemContainer loading={loading} result={result} />
        )}
      </div>
      <ItemModal />
      <Toast data={toast} handleClose={() => dispatch(resetToast())} />
    </div>
  );
}
