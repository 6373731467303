import React, { useEffect, useState } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import EditAddressContainer from "./EditAddressContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import Toast from "../../components/Toast";
import { useParams, useHistory } from "react-router-dom";
import { getUserAddressById } from "../../actions/User";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function EditAddress() {
  const params = useParams();
  const history = useHistory();
  const [appDrawer, setAppDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  useEffect(() => {
    if (params?.id) {
      getUserAddressById(params?.id, setData, setLoading);
    } else {
      history.push("/address");
    }
  }, []);

  return (
    <>
      <SEO
        title={`Edit Address| Kavishala Store`}
        og_url={"/edit-address"}
        og_type="e-commerce"
        og_title={`Edit Address| Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="add-address">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="add-address-container">
          <EditAddressContainer data={data} setToast={setToast} />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
        <Toast
          data={toast}
          handleClose={() =>
            setToast({
              open: false,
              isSuccess: false,
              msg: "",
            })
          }
        />
      </div>
    </>
  );
}
