import React, { useState, useEffect } from "react";
import PrimaryCTA from "../../components/StyledComponent/PrimaryCTA";
import userIcon from "./../../images/Info/name.svg";
import phoneIcon from "./../../images/Info/phone.svg";
import emailIcon from "./../../images/Info/email.svg";
import InfoInput from "./InfoInput";
import { updateUserInfo } from "../../actions/User";

export default function PersonalDetailsContainer({
  slug,
  setToast,
  data,
  refetch,
}) {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEdit(false);
    setName(data?.username);
    setEmail(data?.email);
    setPhone(data?.phone);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setToast({
        open: true,
        isSuccess: false,
        msg: "Phone number must be of 10 digits",
      });
      return;
    }

    const data = {
      username: name,
      phone: phone,
    };

    setLoading(true);
    updateUserInfo(slug, data, setLoading, refetch);
  };

  return (
    <div className="personal-detail-box">
      <h1 className="personal-detail-txt">Personal Details</h1>
      <div className="card-container">
        <form onSubmit={handleSubmit}>
          <InfoInput
            value={name}
            onChange={(e) => setName(e)}
            edit={edit}
            img={userIcon}
            type="Name"
          />
          <InfoInput
            value={phone}
            onChange={(e) => setPhone(e)}
            edit={edit}
            img={phoneIcon}
            type="Phone"
          />
          {!edit && (
            <InfoInput
              value={email}
              edit={false}
              img={emailIcon}
              type="Email"
            />
          )}

          {edit && (
            <PrimaryCTA
              disabled={loading}
              loading={loading}
              type="submit"
              text={"Save"}
            />
          )}
        </form>
        {!edit && (
          <PrimaryCTA onClick={() => setEdit(true)} text={"Edit Profile"} />
        )}
      </div>
    </div>
  );
}
