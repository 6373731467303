import React, { useState, useEffect } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import ShippingAddressContainer from "./ShippingAddressContainer";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../../actions/User";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";

export default function ShippingAddress() {
  const dispatch = useDispatch();
  const [appDrawer, setAppDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getUserAddress(setLoading));
  }, []);

  return (
    <>
      <SEO
        title={`Shipping Address | Kavishala Store`}
        og_url={"/shipping-address"}
        og_type="e-commerce"
        og_title={`Shipping Address | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <div className="shipping-address">
        <AppBar openDrawer={() => setAppDrawer(true)} />
        <div className="shipping-address-container">
          <ShippingAddressContainer setToast={setToast} />
          <DesktopFooter />
          <MobileFooter />
        </div>
        <AppBarDrawer
          open={appDrawer}
          handleClose={() => setAppDrawer(false)}
        />
        <Toast
          data={toast}
          handleClose={() =>
            setToast({
              open: false,
              isSuccess: false,
              msg: "",
            })
          }
        />
      </div>
    </>
  );
}
