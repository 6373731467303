import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  toast: {
    open: false,
    isSuccess: false,
    msg: "",
  },
  itemModal: {
    open: false,
    item: {},
  },
};

const appSlice = createSlice({
  name: "toast",
  initialState: defaultState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload;
    },
    resetToast: (state) => {
      state.toast = { open: false, isSuccess: false, msg: "" };
    },
    setItemModal: (state, action) => {
      state.itemModal = action.payload;
    },
  },
});

export const { setToast, resetToast, setItemModal } = appSlice.actions;
export default appSlice.reducer;
