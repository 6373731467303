import React, { useState } from "react";
import CheckBox from "../../components/StyledComponent/CheckBox";
import DownArrow from "../../images/Listing/DownArrow.svg";
import { useDispatch } from "react-redux";
import {
  getListingProducts,
  getProductFilterListing,
} from "../../actions/Listing";
export default function BrandContainer({ data }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({});
  const [expand, setExpand] = useState(false);

  const handleCheckBox = (id, name, check) => {
    // let temp = { ...checked, [name]: check };
    // setChecked(temp);
    let temp = {};
    // If the current checkbox is being checked, uncheck all others
    if (check === true) {
      Object.keys(checked).forEach((key) => {
        temp[key] = false;
      });
    } else {
      temp = { ...checked };
    }
    temp[name] = check;
    setChecked(temp);
    let tempData = {
      id: check === true ? id : "",
    };
    getProductFilterListing(tempData);
    dispatch(getProductFilterListing(tempData));
  };

  return (
    <div>
      <div onClick={() => setExpand(!expand)} className="heading">
        <h1>Brands</h1>
        <img src={DownArrow} alt="" />
      </div>

      {expand && (
        <div className="listing-check-box-group">
          {data?.map((data, index) => (
            <CheckBox
              label={data?.name}
              onClick={() =>
                handleCheckBox(data?.id, data?.name, !checked?.[data?.name])
              }
              checked={checked?.[data?.name]}
            />
          ))}
        </div>
      )}
    </div>
  );
}
