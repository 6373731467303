import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setItemModal } from "../../reducer/AppReducer";
import CloseIcon from "../../images/Modal/Close.svg";
import PrimaryCTA from "../StyledComponent/PrimaryCTA";
import { addItemToCart } from "../../actions/Cart";
import { useHistory } from "react-router";

export default function ItemModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inCart, setInCart] = useState(false);
  const { itemModal } = useSelector((state) => state.App);
  const { item } = itemModal;
  const { cartId } = useSelector((state) => state.Cart);
  const { cartItems } = useSelector((state) => state?.Cart);

  useEffect(() => {
    let index = cartItems?.findIndex((e) => e.product?.id === item?.id);
    if (index !== -1) {
      setInCart(true);
    } else {
      setInCart(false);
    }
  }, [cartItems, item?.id]);

  const handleClose = () => {
    dispatch(
      setItemModal({
        open: false,
        item: {},
      })
    );
  };

  const handleAddToCart = () => {
    let data = {
      product_id: item?.id,
      count: qty,
    };

    setLoading(true);
    dispatch(addItemToCart(data, setLoading, handleClose));
  };

  return (
    <Modal className="modal" open={itemModal?.open} onClose={handleClose}>
      <div className="paper">
        <div className="item-modal">
          <div className="left">
            <img src={item?.image} alt="" />
          </div>
          <div className="right">
            <div className="close">
              <img onClick={handleClose} src={CloseIcon} alt="" />
            </div>
            <h1>{item?.name}</h1>
            <div className="price">
              <p>₹ {item?.discountPrice}</p>
              <p>
                <span>₹ {item?.price}</span>
              </p>
            </div>

            {!inCart ? (
              <>
                <div className="qty">
                  <h1>Quantity :</h1>
                  <div className="btn-group">
                    <button
                      onClick={() => qty !== 1 && setQty(qty - 1)}
                      className="btn-3"
                    >
                      -
                    </button>
                    <button className="btn-2">{qty}</button>
                    <button onClick={() => setQty(qty + 1)} className="btn-1">
                      +
                    </button>
                  </div>
                </div>

                <div className="cta-container">
                  <PrimaryCTA
                    loading={loading}
                    onClick={handleAddToCart}
                    text="Add To Cart"
                  />
                </div>
              </>
            ) : (
              <div className="already-in-cart">
                <h1>Already in Cart</h1>
                <PrimaryCTA
                  onClick={() => history.push("/cart")}
                  text="Go to Cart"
                />
              </div>
            )}

            <div className="view-detail">
              <p onClick={() => history.push(`/product/${item?.id}`)}>
                View Details
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
