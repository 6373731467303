import React, { useEffect, useState } from "react";
import DesktopFooter from "../../components/Footer/DesktopFooter";
import AppBar from "../../components/TopBar/AppBar";
import CartContinerr from "./CartContinerr";
import AppBarDrawer from "../../components/TopBar/AppBarDrawer";
import { useSelector, useDispatch } from "react-redux";
import { getCartItems } from "../../actions/Cart";
import Toast from "../../components/Toast";
import { resetToast } from "../../reducer/AppReducer";
import MobileFooter from "../../components/Footer/MobileFooter";
import SEO from "../../SEO";
export default function Cart() {
  const { toast } = useSelector((state) => state?.App);
  const [appDrawer, setAppDrawer] = useState(false);
  const dispatch = useDispatch();
  const { cartId, cartItems } = useSelector((state) => state.Cart);
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state.User);

  useEffect(() => {
    setLoading(true);
    dispatch(getCartItems(isLogin, setLoading));
  }, [isLogin, dispatch]);
  const path = window.location.pathname;
  localStorage.setItem("path", path);
  return (
    <div className="cart">
      <SEO
        title={`Shoping Cart | Kavishala Store`}
        og_url={"/cart"}
        og_type="e-commerce"
        og_title={`Shoping Cart | Kavishala Store`}
        og_description={
          "Discover a treasure trove of cultural elegance and artistic finesse at Kavishala Store – where every item tells a story worth cherishing."
        }
        og_image="https://store.kavishala.com/images/post_og.png"
      />
      <AppBar openDrawer={() => setAppDrawer(true)} />
      <div className="cart-container">
        <CartContinerr />
        <DesktopFooter />
        <MobileFooter />
      </div>
      <Toast data={toast} handleClose={() => dispatch(resetToast())} />
      <AppBarDrawer open={appDrawer} handleClose={() => setAppDrawer(false)} />
    </div>
  );
}
