import React from "react";
import Check from "./Check";

export default function CheckBox({ onClick, checked, label, index }) {
  console.log("hgfghj", checked, label, index);
  return (
    <div onClick={onClick} className="custom-check-box">
      <Check checked={checked} />
      <h1>{label}</h1>
    </div>
  );
}
