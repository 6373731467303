import React from "react";

import CheckIcon from "./../../images/CheckBox/check_unselected.svg";
import CheckSelectedIcon from "./../../images/CheckBox/check_selected.svg";
export default function Check({ checked }) {
  return (
    <div className="custom-check">
      <img src={checked ? CheckSelectedIcon : CheckIcon} alt="" />
    </div>
  );
}
